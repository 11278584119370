
.leadership-container{
    margin: 100px auto;
    @include media-breakpoint-down(md) {
        margin: 40px auto;
    }

    .text-center h2.mb-0.mt-5{
        margin: 0 0 0 0 !important;
        @include media-breakpoint-down(md) {
            margin: 0 0 10px 0 !important;
        }
    }

    .row{
        .col-md-12.leadership-component:last-child{
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .media{
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

.leadership-component{
    
    padding: 60px 0px 0;

    @include media-breakpoint-down(md) {
        padding: 30px 0px 10px;
    }
    @include media-breakpoint-down(sm) {
        padding: 30px 28px 10px;
    }

    .media-image {
        
        -webkit-box-shadow: 0px 0px 20px -6px #05347a;
        -moz-box-shadow: 0px 0px 20px -6px #05347a;
        box-shadow: -15px 15px 0px -3px #05347a;
        max-width: 300px;
        width: 100%;
        margin: 0 auto 30px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    
    &.col-md-12{
        
        .media-image{
            margin-right: 120px;
            left: 13px;
            position: relative;
            @include media-breakpoint-down(md) {
                margin-right: 60px;
            }
            @include media-breakpoint-down(sm) {
                margin: 12px;
                margin: 0 auto 40px;
            }
        }
        &.col-sm-6{
            max-width: 100%;
            flex: 0 0 100%;
        }
        .media{
            border-bottom: 1px solid #ccc;
            padding-bottom: 60px;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                margin-bottom: 10px;
                padding-bottom: 24px;
            }
        }

        .media-body{
            p strong{
                font-weight: bold;
            }
        }
    }
    &.col-md-4{
        text-align: center;
        padding: 60px 24px 0;

        @include media-breakpoint-down(md) {
            padding: 60px 12px 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 28px 0;
        }

        &.col-sm-6{
            @include media-breakpoint-down(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        .media{
            flex-direction: column;

        }
        
        .media-body {
            text-align: center;
            width: 100%;
                    
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {                
                min-height: 220px;
            }
            h4{
                font-size: 1.563rem;
                font-weight: bold;
                line-height: 32px;
            }
            p strong{
                font-weight: bold;
            }
        }
        
        .media-image {
            max-width: 200px; 
        }
    }

    
}
