table{
    
}

table.responsive-fixed-col {
    table-layout: fixed;
      position: relative;
      width: 100%; /*700px;*/
      overflow: hidden;
      border-collapse: collapse;

    thead {
        position: relative;
        display: block; /*seperates the header from the body allowing it to be positioned*/
        width: 100%; /*700px;*/
        overflow: visible;

        th:nth-child(1) {/*first cell in the header*/
            position: relative;
            display: block; /*seperates the first cell in the header from the header*/
            background: white;
        }
    }
    
    tbody {
        position: relative;        
        width: 100%; /*700px;*/
        overflow-x: auto;
        overflow-y: hidden;
        display: block; /*seperates the tbody from the header*/      

        tr td:nth-child(1) {  /*the first cell in each tr*/
            position: relative;
            display: block; /*seperates the first column from the tbody*/
            background-color: white;
        }  
    }  
}
    
    