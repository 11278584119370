.breadcrumb {
    background: none;
    padding: 0;
    display: block;

    // Ellipsis
    &.ellipsis {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a {
        color: $main-text;
    }

    span {
        margin-left: 5px;
        font-weight: bold;
    }

    .breadcrumb-item.active {
        color: $mobile-menu-active;
        font-weight: bold;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        content: ">";
        padding-right: .3rem;
    }

    .breadcrumb-item+.breadcrumb-item {
        padding-left: .3rem;
    }

    // li {
    //     display: inline; // force the inlining
    //     max-width: 36%; // the actual width, you can make this bit responsive, increasing the width as your viewport increases. I suggest to utilize the native Bootstrap media-breakpoints (i.e. media-breakpoint-up(md)).
    //     white-space: nowrap; // remove the wrapping and breaking of text in the breadcrumbs
    //     overflow: hidden; // hide our overflow
    //     text-overflow: ellipsis; // return ellipsis for the overflow.
    // }
    // &.full-width{
    //     li{
    //         max-width: 100%;
    //     }
    // }

    li {
        display: inline;
    }
}