.form-group{
    label{
        display: block;
        font-size: 1rem;
        color: $paragraph;
        line-height: 25px;
        letter-spacing: 0.5px;
        margin: 0 0 6px 0;
        font-weight: 300;

        .tooltip{
            float: right;
            position: relative;
            top: 2px;
            opacity: 1;
            color: $tcdrs-blue;
            font-size: 1rem;
            // .icon-information-solid{                
            // }        
        }
        .icon-cancel-24px{
            display: none;
            color: $error;
            float: right;
            position: relative;
            top: 2px;
            font-size: 1.188rem;
        }
        .icon-done-24px{
            display: none;
            color: white;
            float: right;
            position: relative;
            top: 2px;
            font-size: 0.875rem;
            border-radius: 100%;
            background: #1AAA55;
            padding: 1px;
        }
    }
    .form-control {
        display: block;
        width: 100%;
        height: 60px;
        padding: 12px 20px;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
        color: $paragraph;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid $graphite;
        border-radius: 28px;

        @include media-breakpoint-down(sm) {
            height: 50px;
        }

        &.search-input {
            background-image: url(../img/search-icon-02.svg);
            background-position: 20px center;
            background-repeat: no-repeat;
            padding-left: 50px;
        }        

    }
    .form-text {
        color: #000 !important;
        font-size: 0.875rem;
        font-size: 1rem;
    }
    .invalid-feedback {
        display: none;
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: $error;
        font-size: 0.875rem;
    }

    &.valid{
        label{
            .icon-done-24px{
                display: block;   
            }
        }
        .form-control {
            border-color: $confirmation;
            background-image: none;
            padding: 12px 20px;
        }
    }

    &.error{
        label{
            .icon-cancel-24px{
                display: block;              
            }
        }
        .invalid-feedback {
            display: block;           
        }
        .form-control {
            border-color: $error;
            background-image: none;
            padding: 12px 20px;
        }
    }
}


.tooltip{opacity: 1;}
.tooltipster-sidetip {
	.tooltipster-box {
		background: #fff;
		border: 1px solid $tcdrs-blue;
		border-radius: 0;
	}
}
.tooltipster-sidetip.tooltipster-light {
	.tooltipster-box {
		border-radius: 0px;
		border: 1px solid $tcdrs-blue;
		background: white;
	}
}
.tooltipster-sidetip.tooltipster-light.tooltipster-right {
	.tooltipster-arrow-border {
		border-right-color: $tcdrs-blue;
	}
	.tooltipster-arrow-background {
		border-right-color: white;
		left: 1px;
	}
}

.form-container{
    .form-container-column{
        padding: 60px 0;
        @include media-breakpoint-down(md) {
            padding: 0px 0 40px;
        }
        @include media-breakpoint-down(sm) {
            padding: 0px 0 40px;
        }
        &:first-child{
            padding: 100px 0;
            @include media-breakpoint-down(md) {
                padding: 40px 0 0;
            }

            @include media-breakpoint-down(sm) {
                padding: 40px 0 0;
            }
        }
        
    }

    .col-4, .col-6{
        @include media-breakpoint-only(lg) {
            width: 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        @include media-breakpoint-down(xs) {
            width: 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
}

.EPiServerForms {
    border-radius: 10px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
    -moz-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
    box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
    padding: 40px 55px;
    //padding: 60px 0;
    text-align: center;

    @include media-breakpoint-down(sm) {
        padding: 40px 30px 20px 30px;
    }

    &.ValidationSuccess { //when valid form shows a thank message
        //.Form__Title, .Form__Description{display: none;}
    }

    aside, .Form__Description {
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.75rem;
        letter-spacing: 0.5px;
    }

    p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5625rem;
        letter-spacing: 0.5px;
    }

    .Form__Title {
        color: $tcdrs-blue;
        font-size: 1.5625rem;
        font-weight: 700;
        line-height: 2rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.375rem;
            font-weight: 400;
            line-height: 2rem;
        }
    }

    .Form__MainBody, .epi-editContainer { //epi-editContainer is for edit mode
        .Form__Element {
            margin-top: 25px;
            

            @include media-breakpoint-down(sm) {
                margin-top: 20px;
                margin-bottom: 0;
            }

            label {
                width: 100%;
                text-align: left;
                display: block;
                font-size: 1rem;
                color: $paragraph;
                line-height: 25px;
                letter-spacing: 0.5px;
                margin: 0 0 6px 0;
                font-weight: 300;
            }

            input[type=text],
            input[type=number] {
                width: 100%;
                display: block;
                width: 100%;
                height: 60px;
                padding: 12px 20px;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.5;
                color: $paragraph;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid $graphite;
                border-radius: 28px;

                @include media-breakpoint-down(sm) {
                    height: 50px;
                }
            }

            select {
                width: 100%;
            }

            textarea {
                display: block;
                width: 100%;
                padding: 12px 20px;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.5;
                color: $paragraph;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid $graphite;
                border-radius: 28px;
                resize: none;
                height: 300px;

                @include media-breakpoint-down(sm) {
                    height: 300px;
                }
            }

            button[type=submit] {
                color: white;
                border-radius: 0;
                letter-spacing: 1.471px;
                line-height: 1.25rem;
                margin: 25px 0 20px;
                padding: 19px 20px;
                display: inline-block;
                text-transform: uppercase;
                font-weight: 400;
                background: $main-red;
                border: 1px solid $main-red;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 100%;
                }

                &:hover,
                &:active {
                    background: white;
                    color: $main-red;
                    border: 1px $main-red solid;
                }

                &::after {
                    content: "\e90d";
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    margin-left: 8px;
                    position: relative;
                    top: 2px;
                    transition: transform 0.22s ease-out;
                }

                &:hover::after {
                    transform: translateX(5px);
                }
            }
        }
    }

    .Form__MainBody .Form__Element.FormTextbox.FormTextbox--Textarea{
        position:relative;
        &.ValidationFail{
            margin-bottom: 25px;
            @include media-breakpoint-down(xs) {
                margin-bottom: 25px;
            }
        }
        .Form__Element__ValidationError{ position: absolute;}
    }

    //thank you message
    .Form__Status .Form__Success__Message {
        background-color: white !important;
        color: $paragraph !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .epi-editContainer {

        .Form__Element select {
            display: block;
            height: 50px;
        }

        button {
            border-radius: 0;
            color: white;
            padding: .75rem 1.25rem;
            letter-spacing: 1.471px;
            line-height: 1.25rem;
            padding: 19px 20px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 400;
            //transition: transform 0.3s ease-out;
            background: #7F001A;
            border: 1px solid #7F001A;

            &:hover,
            &:active {
                background: white;
                color: #7F001A;
                border: 1px #7F001A solid;
                text-decoration: none;
            }
            //with arrow defautl in edit mode
            &::after {
                content: "\e90d";
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                margin-left: 8px;
                position: relative;
                top: 2px;
                transition: transform 0.22s ease-out;
            }

            &:hover::after {
                transform: translateX(5px);
            }
        }
    }

    .Form__Element {
        &.ValidationFail {
            input[type=text],
            input[type=number],
            input[type=email],
            select,
            .custom-select .select-selected,
            textarea {
                border-color: $error;
            }
        }

        &.ValidationSuccess {
            input[type=text],
            input[type=number],
            input[type=email],
            select,
            .custom-select .select-selected,
            textarea {
                border-color: $confirmation !important;
            }
        }
    }

    .Form__Element .Form__Element__ValidationError {
        width: 100%;
        margin-top: .25rem;
        color: $error;
        font-size: 0.875rem;
        text-align: left;
    }

    .Form__Status {
        .Form__Status__Message {
            padding: 0;
        }
    }


    .custom-select .select-items {
        max-height: 445px;
        overflow-y: auto;

        div {
            padding: 5px 20px;
        }
    }
}



//custom select in episerverforms
/*the container must be positioned relative:*/
.custom-select {
    position: relative;
    padding: 0;
    border: none;
    height: 60px;
    line-height: 28px;

    @include media-breakpoint-down(sm) {
        height: 50px; 
    }

    select {
        display: none; /*hide original SELECT element:*/
    }

    /*style the items (options), including the selected item:*/
    .select-selected {
        color: $paragraph;
        background: white;
        border: 1px solid $graphite;
        font-weight: 300;
     
        cursor: pointer;
        user-select: none;
        height: 60px;
        padding: 14px 40px 14px 20px;
        border-radius: 28px;
        text-align: left;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
            height: 50px;
            padding: 12px 40px 12px 20px;
            line-height: 24px;   
        }
        /*style the arrow inside the select element:*/
        &::after {
            position: absolute;
            content: "";
            top: 12px;
            right: 10px;
            border: 6px solid transparent;
            color: $tcdrs-blue;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: 900;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e906";
            font-size: 1.5rem;
            transform: rotate(-90deg);
            @include media-breakpoint-down(sm) {
                top: 9px;
            }
        }

        &.select-arrow-active {
            border-radius: 28px 28px 0 0;
        }
        /*point the arrow upwards when the select box is open (active):*/
        &.select-arrow-active:after {
            transform: rotate(90deg);
        }       
    }

    /*style items (options):*/
    .select-items {
        position: absolute;
        background-color: white;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        border: 1px solid $graphite;
        border-top: none;
        border-radius: 0 0 28px 28px;
        overflow: hidden;

        -webkit-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        -moz-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);

        div{
            text-align: left;
            padding: 5px 35px;       
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.5px;
            line-height: 25px;
            &.disable{
                opacity:0.3;
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        div:hover, .same-as-selected{
            // background-color: rgba(0, 0, 0, 0.1);
            background-color: $tcdrs-blue;
            color:white;
        }
        /*hide the items when the select box is closed:*/
        &.select-hide {
            display: none;
        }
    }
  
}

.dropdown.custom-dropdown{
    height: 50px;
    display: block;    
    margin: 0;
    position: relative;

    .dropdown-toggle{
        color: $paragraph;
        background: white;
        border: 1px solid $graphite;
        font-weight: 300;
     
        cursor: pointer;
        user-select: none;
        height: 50px;
        padding: 14px 40px 14px 20px;
        border-radius: 28px;
        text-align: left;
        text-transform: none;
        width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
            height: 50px;
            padding: 12px 40px 12px 20px;
            line-height: 24px;   
        }
        &:focus{
            outline: none;
            box-shadow: none;
        }
        /*style the arrow inside the select element:*/
        &::after {
            position: absolute;
            content: "";
            top: 7px;
            right: 7px;
            border: 6px solid transparent;
            color: $tcdrs-blue;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: 900;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e906";
            font-size: 1.5rem;
            transform: rotate(-90deg);
            @include media-breakpoint-down(sm) {
                top: 9px;
            }
        }
    }

    &.show{
        .dropdown-toggle{
            border-radius: 28px 28px 0 0;
            &::after{
                transform: rotate(90deg);
            }
        }
    }

    .dropdown-menu{
        position: absolute;
        background-color: white;
        top: 48px !important;
        left: 0;
        right: 0;
        z-index: 99;
        border: 1px solid $graphite;
        border-top: none;
        border-radius: 0 0 28px 28px;
        overflow: hidden;
        padding: 0;
        width: 100% !important;
        min-width: 100%;

        transform: none !important;

        -webkit-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        -moz-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);

        .dropdown-item{
            text-align: left;
            padding: 5px 5px 5px 20px;
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.5px;

            line-height: 34px;
            height: 44px;
            border-bottom: 1px solid #979797;
            &:hover{
                background-color: $tcdrs-blue;
                color: white
            }

            &.download-icon{
                background: url("../img/ico-download.png") 90% 6px no-repeat transparent;
                &:hover{
                    background: url("../img/ico-download-hover.png") 90% 9px no-repeat $tcdrs-blue;
                }
            }

            &:last-child{
                border-bottom: none;
            }
        }
    }

}

.ui-menu.ui-widget.ui-widget-content.ui-autocomplete{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: white;
    border: 1px solid #6d7278;
    border-top: none;
    border-radius: 0 0 28px 28px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
    -moz-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
    box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover,
.ui-menu .ui-menu-item-wrapper:hover {
    background: #05347A !important;
    border: none;
    color: white;
    margin: 0;
}

//hide countdown msg for reCapcha control
.g-recaptcha span.countdown {
    display: none;
}

//textarea countdown msg
span.countdown{
    color: $paragraph;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 25px;
    position: relative;
    top: -40px;
    right: 20px;
    display: block;
    float: right;
    width: 55px;
    margin: 0;
    padding: 0;
}



/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {  
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}





//contact us:
.form-address-box{
    margin-bottom: 30px;
    p{      
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5625rem;
        letter-spacing: 0.5px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 1.5625rem;
        }
          
    }
}

.thank-you-box{
    //margin-bottom: 40px;
    .text-container{
        //padding: 40px;
        border-radius: 10px;
        a{
            display: block;
            width: 100%;
        }
    }
}

.member-services-box{
    margin-bottom: 40px;
    .text-container{
        padding: 40px;
        border-radius: 10px;
        .top-image{
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
        }
    }
}
.employer-services-box{
    margin-bottom: 40px;
    .text-container{
        padding: 40px;
        border-radius: 10px;
        .top-image{
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
        }
    }
}

.read-all-box{
    margin-bottom: 40px;  
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }  
    .text-container{
        background-color: #F0F0F0;
        padding: 40px;
        border-radius: 10px;        
    }
}
