.featured-publications {

    padding: 100px 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    >.text-center {
        @include media-breakpoint-down(sm) {
            margin-bottom: 0px !important;
        }
    }

    h4 {
        font-size: 1rem;
        font-weight: 400;
        color: $paragraph;
        line-height: 1.5625rem;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
    }

    h2 {
        // font-size: 3.4375rem;
        font-weight: 300;
        color: black;
        // line-height: 3.75rem;
        // text-transform: capitalize;
        margin-bottom: 10px;

        strong,
        b {
            color: #05347A;
            font-weight: 700;
        }

        // @include media-breakpoint-down(sm) {
        //     font-size: 2.1875rem;
        //     line-height: 2.25rem;
        // }        
    }

    p {
        font-size: 1.125rem;
        font-weight: 300;
        color: $paragraph;
        line-height: 1.75rem;
    }

    a.button {

        // background-color: $main-red;
        // border: 1px solid $main-red;
        // font-size: 1rem;
        // font-weight: 400;
        // line-height: 3.75rem;
        // padding: 0 20px;
        // display: inline-block;
        // text-transform: uppercase;
        // &:hover{            
        //     i{
        //         color: $main-red;
        //     }
        // }
        // i{
        //     font-size: 1.125rem;
        //     margin-left: 10px;
        // }
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .carousel {


            padding-bottom: 0 !important;;           
            margin-bottom: 36px !important;; 
    

        @include media-breakpoint-down(sm) {
            margin-top: -12px;
            margin-bottom: 30px !important;
            padding-bottom: 24px !important;
        }

        .slick-dots {
            @include media-breakpoint-down(sm) {
                bottom: -5px;
            }
        }
    }

    .slick-slide {
        margin-bottom: 35px;
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: none;
        border-radius: 10px;
        overflow: hidden;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        font-size: 1rem;
        line-height: 1.5625rem;
        height: 100%;

        .video-icon {
            background: white;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            right: 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }

            img {
                margin-left: 7px;
                margin-top: 7px;
            }
        }

        .btn.with-arrow {
            position: absolute;
            bottom: 32px;
            text-align: left;
            width: auto;
        }

        .card-text {
            margin-bottom: 55px;
        }

        .card-body {
            padding: 30px 40px;
        }

        .card-img,
        .card-img-top {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .card-img-top {
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        .card-category {
            // text-transform: uppercase;
            border: 2px solid black;
            background-color: #fff;
            color: black;
            display: inline-block;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5625rem;
            padding: 8px 10px;

            &.case-studies {
                color: $main-red;
                border-color: $main-red;

                &:hover {
                    color: $main-red !important;
                }
            }

            &.press {
                color: #053A38;
                border-color: #053A38;

                &:hover {
                    color: #053A38 !important;
                }
            }

            &.business-updates {
                color: #AC5914;
                border-color: #AC5914;

                &:hover {
                    color: #AC5914 !important;
                }
            }
        }

        .card-date {
            color: $paragraph;
        }

        h4 {
            font-size: 1.5625rem;
            line-height: 1.875rem;
            color: $mobile-menu-active;
            text-transform: none;

            a {
                font-size: 1.5625rem;
                font-weight: 400;
                line-height: 1.875rem;
                color: $mobile-menu-active;
                text-transform: none;

                &:hover {
                    text-decoration: underline;
                    color: $mobile-menu-active !important;
                }
            }
        }

        p {
            color: $paragraph;
            font-size: 1rem;
            line-height: 1.5625rem;
        }

        // .btn-secondary{
        //     text-transform: uppercase;
        //     color: $main-red;
        //     &:hover{
        //         color: #fff !important;
        //     }
        // }
    }


}

// Slick dots main styles

.slick-list {
    padding: 18px 0;
}

.slick-dots {
    bottom: -15px;

    li {
        margin: 0 15px;

        @include media-breakpoint-down(sm) {
            height: 15px;
            width: 15px;
        }

        button {
            border: 1px solid $mobile-menu-active;
            border-radius: 100%;

            @include media-breakpoint-down(sm) {
                height: 15px;
                width: 15px;
            }

            &::before {
                content: none;
            }
        }
    }

    li.slick-active {
        button {
            border: 2px solid $mobile-menu-active;
            border-radius: 100%;
            background: $mobile-menu-active;
        }
    }
}