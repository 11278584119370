.job-listing-item{
    padding: 100px 0;

    @include media-breakpoint-down(lg) {    
        padding: 100px 0;
    }
    @include media-breakpoint-down(md) {    
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {    
        padding: 40px 0;
    }

    .text-container-card{
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        height: 100%;
        padding: 80px 55px;
        h3{
            color: $tcdrs-blue;
        }
    }
}

.job-listing{
    padding: 100px 0;

    @include media-breakpoint-down(lg) {    
        padding: 100px 0;
    }
    @include media-breakpoint-down(md) {    
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {    
        padding: 40px 0;
    }

    hr{border-color: $graphite;}
    .job-list-item{
        border-bottom: 1px solid $graphite;
        padding: 40px 0;

        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.75rem;
        letter-spacing: 0.5px;

        &:first-child{
            border-top: 1px solid $graphite;
        }
        @include media-breakpoint-down(md) {    
            padding: 40px 0px;
            margin: 0 !important;
            .text-column{
                padding: 0 0px 15px 0 !important; 
                text-align: center;
            }
            .buttons-container{padding: 0px !important; }
        }

        .text-column{
            a{
                text-decoration: underline;
                color: $tcdrs-blue;
            }     
            p{
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.75rem;
                letter-spacing: 0.5px;
            }       
        }
        
        
        .buttons-container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include media-breakpoint-down(sm) {  
                a{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}