i {
  color: white;

  &:active {
    background: none !important;
  }

  &.rounded {
    background: transparent;
    border: 1px solid black;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    padding: 0;
    border-radius: 15px !important;
    text-align: center;
    line-height: 2;
  }
}

// Top Nav Icons
span.rounded {
  background: transparent;
  border: 1px solid black;
  font-size: 2rem;
  padding: 0;
  border-radius: 1rem !important;
  text-align: center;
  margin-right: .1rem;
}

// SVG Icons
svg {
  path {
    //fill: white; //should be more specific, this affects all svg's
  }
}

//Nav Icons
.icon {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-size: cover;
}

.icon-employers {
  background-image: url('../img/employers-icon.svg');
}

.icon-members {
  background-image: url('../img/members-icon.svg');
}
.icon-search {
  background-image: url('../img/search-icon.svg');
}