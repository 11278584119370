.side-by-side {
    background-color: #fff;
    &.bg-lBlue {
        background-color: #E9F6FF;

        @include media-breakpoint-up(xl) {
            overflow: hidden; 
            position: relative;
            .container{
                max-width: 1440px !important; 
                overflow: hidden; 
                position: relative;
        
            }
        }

        .media-container{
            padding: 0;

            @include media-breakpoint-down(sm) {
                padding: 0 !important;
            }
            &.order-md-2{
                justify-content: flex-start;
            }
            .img-fluid {
                width: 882px;
                max-width: 882px;
                
                @include media-breakpoint-up(md) {
                    position: relative;
                    left: -70%;
                    //
                    flex-shrink: inherit; //firefox fix

                }
                @include media-breakpoint-up(lg) {
                    position: relative;
                    left: -24%;
                    //
                    flex-shrink: inherit; //firefox fix

                }
                @include media-breakpoint-only(sm) {
                    position: relative;
                    left: -24%;
                }
                @include media-breakpoint-down(xs) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            &.video{
                &.order-md-2{
                    @include media-breakpoint-up(xl) {   
                        justify-content: flex-start;
                        padding: 120px 0px 120px 8px;
                    }
                }
                &.order-md-0{
                    @include media-breakpoint-up(xl) {   
                        justify-content: flex-end;
                        padding: 120px 8px 120px 0px;
                    }
                }
                
                .img-fluid {
                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                    }
                }
            }

            &.order-md-2{
                .img-fluid{
                    float: none;
                }
            }

            
        }
        .media-container:not(.video) {
            @include media-breakpoint-up(xl) {                
                min-height: 594px;
        
                img.img-fluid{
                    position: absolute;
                    right: -77px;
                    left: unset;
                    top: 0;                    
                }
            }
        }

        //new version for 1200 and up only in bgblue
        @include media-breakpoint-up(xl) {
            .text-column{
                justify-content: flex-start;
                &.order-md-0{
                    justify-content: flex-end;
                }
            }

            .text-container{
                max-width: 600px; 
                padding-left: 114px;
            }
                
            
        }

    }
    .text-column{
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(md) {
            padding-bottom:40px !important;
        } 

        &.order-md-0{
            .text-container{
                @include media-breakpoint-up(md) {
                    padding-left:0;
                }                
            }
        }
        &.order-md-2{
            .text-container{
                @include media-breakpoint-up(md) {
                    padding-right:0;
                }                
            }
        }

        .text-container{
            padding: 70px 19%;
            @include media-breakpoint-down(md) {
                padding: 40px 19% 0;
             }
            @include media-breakpoint-down(sm) {
               padding: 40px 12px 0;
            }
            @include media-breakpoint-down(xs) {
                padding: 40px 0px 0px;
                text-align: center;
            }

            ul li{
                text-align: left;
                a{
                    text-decoration: underline;
                }
            }
        }
    }

    .media-container{
        overflow: hidden;
        padding: 100px 0px;

        @include media-breakpoint-down(md) {
            padding: 40px 0px;
        }

        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.order-md-2{
            justify-content: flex-end;
            .img-fluid {
                float: right; //right;
            }
        }
        .img-fluid {
            width: 100%;
            max-width: 588px;
            float: left;            
        }

        &.video{
            padding: 120px 0px;  
            .img-fluid {
                left: 0;
                width: 100%;
                max-width: 588px;
                position: static;
                -webkit-box-shadow: 10px 10px 0px -3px rgba(5,52,122,1);
                -moz-box-shadow: 10px 10px 0px -3px rgba(5,52,122,1);
                box-shadow: 10px 10px 0px -3px rgba(5,52,122,1);
                
                margin: 0;
                top: 0;
                transform: none;
            }
            &.order-md-2{
                padding-left: 7px;
                .img-fluid {
                    float: right;
                    margin-right: 7px;
                    @include media-breakpoint-down(sm) {
                        margin-right: 0px;
                    }
                }
            }
            &.order-md-0{
                padding-right: 7px;
            }
            span.icon-PlayButton-1 {
                background: transparent;
                color: #05347A;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 100%;
                border: none;
                font-size: 3.125rem;
                display: block;
            }
            i.fas{
                background: #fff;
                color: #05347A;
                display: inline-block;
                padding: 15px 17px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 100%;
                font-size: 2rem;           
                border: none;
                &::before{
                    position: relative;
                    left: 3px;
                }
            }
            a{
                position: relative;
            }
            @include media-breakpoint-down(sm) {
                padding: 40px 15px 7px !important
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 15px 0px;
        }

    }

    // h4{
    // }
    h2{
        font-size: 3.4375rem;
        font-weight: 300;
        color: black;
        line-height: 3.75rem;
        text-transform: none; //capitalize;
        margin-bottom: 10px;
        strong, b{
            color: #05347A;
            font-weight: 700;
        }
        @include media-breakpoint-down(md) {
            font-size: 2.1875rem;//35px;
            line-height: 2.25rem; //36px;
        } 
        @include media-breakpoint-down(sm) {
            font-size: 2.1875rem;
            line-height: 2.25rem;
        }        
    }
    p{
        font-size: 1.125rem;
        font-weight: 300;
        color: $paragraph;
        line-height: 1.75rem;
        a.btn{
            margin-top: 14px;
        }
        &:last-child {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
        }
    }
    a.btn{
        
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }


    
    
   
}

.side-by-side-modal-video {
    @include media-breakpoint-down(xs) {
        padding: 0;
    }

    .modal-dialog{
        @include media-breakpoint-down(xs) {
            //margin: 8px 24px;
                -webkit-transform: translate(0,-50%) !important;
                -o-transform: translate(0,-50%)!important;
                transform: translate(0,-50%)!important;
                top: 50%!important;
                margin: 0 24px!important;
        }
    }
    .modal-content {
        border: none;
        border-radius: .7rem;
        outline: 0;
        -webkit-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
    }
    .modal-body {
        padding: 3rem 90px;

        @include media-breakpoint-down(xs) {
            padding: 50px 32px 18px;
        }

        .close {
            float: right;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1;
            color: $tcdrs-blue;
            text-shadow: 0 1px 0 #fff;
            opacity: 1;            
            position: relative;
            right: -70px;
            top: -30px;
            @include media-breakpoint-down(xs) {
                right: -10px;
                top: -40px;
            }
        }

        .modal-title{
            h5{
                @include media-breakpoint-down(xs) {
                    margin-bottom: 28px !important;
                }
            }
        }
        

        div iframe{
            @include media-breakpoint-down(xs) {
                height: 159px;
            }
        }
    }
}

//About US Map

.map-container.side-by-side{
    @media only screen and (max-width : 768px) {
        .map{
            max-width: 80%;
        }

        .media-container {
            padding: 40px 15px 40px;
        }

        .media-container.order-md-2 .img-fluid {
            float: right;
            margin: 0 !important;
        }
    }

    .small-text {
        font-size: 1rem !important;
    }

    .square {
        margin: 0 auto;
        height: 32px;
        width: 32px;
        margin-top: 56px;
    }
    
    .square1 {
        background-color: #7F001A;
    }
    .square2 {
        background-color: #233A75;
    }
    .square3 {
        background-color: #C9D2E2;
    }
    .square4 {
        background-color: #053A38;
    }
    .square5 {
        background-color: #F79C56;
    }
    .square6 {
        background-color: #C56E28;
    }
}