//Import lato font
@import url('//fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

@import "./partials/_bootstrap-override";

// Import Boostrap
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "//use.fontawesome.com/releases/v5.0.8/css/all.css";

//icomoon
@import './partials/_icomoon.scss';

//slick
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';

// Jquery UI
@import url('//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css');

//tooltips
@import './partials/_tooltipster.bundle.scss';

//import kendo ui styles
@import '//kendo.cdn.telerik.com/2018.2.620/styles/kendo.default.min.css';
@import '//kendo.cdn.telerik.com/2018.2.620/styles/kendo.common.min.css';

// Import Partials
@import "./partials/_variables";
@import "./partials/_base-layout";

@import "./partials/_buttons";
@import "./partials/_fonts";
@import "./partials/_icons";
@import "./partials/_media-queries";
@import "./partials/_tables";
@import "./partials/_text";
@import "./partials/_utility";
@import "./partials/_forms";

// Import Components
@import "./components/_breadcrumb";
@import "./components/_footer";
@import "./components/_navigation";
@import "./components/_side-by-side";
@import "./components/_featured-publications";
@import "./components/_tabs-component";
@import "./components/_rich-text-component";
@import "./components/_hero";
@import "./components/_responsive-table";
@import "./components/_carousel-card";
@import "./components/_leadership-component";
@import "./components/_testimonials-component";
@import "./components/_job-listing";
@import "./components/_key-dates";
@import "./components/_plan-finder";
@import "./components/_calculation";
@import "./components/_investment-assets";
@import "./components/_investment-results";
@import "./components/_investment-commitments";
@import "./components/_board-meetings";
@import "./components/_library-post";
@import "./components/_library-search";
@import "./components/_general-search";
