//navigation.scss
header {
    // .top-menu {
    //     border-bottom: 0.5px white solid;
    //     padding: 15px;
    // }
    background: white;
    height: 82px;
    -webkit-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.75);
    height: auto;
    // This should be consider to refactor
    @include media-breakpoint-down(lg) {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

// New menu design
.show-desktop {
    display: none;

    &.search-icon {
        display: none !important;
    }

    @include media-breakpoint-up(xl) {
        display: flex;

        .search-icon {
            display: flex;
            align-items: center;
            // display:block!important;
            //padding-bottom: 0!important;
        }
    }
}

.show-mobile {
    // display:flex;
    position: relative;

    @include media-breakpoint-up(xl) {
        display: none !important;
    }
}

// Navigation Styles
.star-logo {
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

nav.navbar {
    padding: 0;

    .navbar-brand {
        @include media-breakpoint-down(xl) {
            display: none;
        }

        @include media-breakpoint-up(xl) {
            display: block;
        }

        img {
            //margin-right: 20px;
            width: 80px;
        }
    }

    .navbar-toggler {
        // margin-right: 15px;
        // margin-top: 8px;
        padding: 0;
        width: 32px;
        height: 32px;

        @include media-breakpoint-down(lg) {
            margin-right: $mobile-gutter;
        }
    }
    // ie11 hack
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .navbar-toggler {
            position: absolute;
            right: 0;
            top: 7px;
        }
    }

    .login {
        display: none;

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 8px;
            right: 60px;
            display: block;
        }

        .btn {
            padding: 14px 20px;

            @include media-breakpoint-down(lg) {
                margin-top: -3px;
                padding: 7px 10px;
                display: block;
                width: 89px;
                height: 34px;
                display: inline-block;

                &.btn-secondary {
                    padding: 7px 10px;
                    width: auto;
                }
            }

            @include media-breakpoint-down(sm) {
                width: auto;
                margin-right: 10px !important;
            }

            @include media-breakpoint-down(sm) {
                letter-spacing: 0px;
            }
        }

        @include media-breakpoint-down(lg) {

            a {
                display: none;
            }
        }
    }

    .register {
        @include media-breakpoint-up(xl) {
            padding-left: 20px;
            border-left: 1px black solid;
        }

        @include media-breakpoint-down(lg) {
            margin: 1rem;
            position: relative;

            .btn.btn-secondary {
                display: none;
            }
        }

        .btn {
            padding: 14px 20px;

            &:first-child {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }

        a.underline {
            color: $tcdrs-blue;

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: -65px; //top:12px;
                right: 52px;
                z-index: 2;
            }
        }

        .dsk-need-help-link {
            display: block;
            font-size: 14px;
            letter-spacing: -0.2px;
            margin-left: 0 !important;
        }
    }

    @include media-breakpoint-down(lg) {
        &.container {
            padding: 0 !important;
            max-width: 100%;
        }

        .navbar-collapse {
            -webkit-box-shadow: inset -8px 73px 12px -75px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset -8px 73px 12px -75px rgba(0, 0, 0, 0.75);
            box-shadow: inset -8px 73px 12px -75px rgba(0, 0, 0, 0.75);
            // display:flex;
            // @include media-breakpoint-down(lg) {
            //         display:none;
            // }
            // height: 100vh;
            // overflow-y: scroll;
            // &.show{
            //     .show-mobile{
            //         .search-icon{
            //             display:none;
            //         }
            //     }
            // }

            min-height: 100vh;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                height: 100vh;
                overflow-y: auto;
            }
        }

        .star-logo {
            margin-left: 17px;
        }

        .navbar-brand {
            margin-left: $mobile-gutter;

            img {
                width: 65px;
                height: 34px;
                // margin: .5rem 2rem;
            }
        }
    }

    .navbar-toggler-icon {
        // width:32px;
        // height:32px;
        display: inline;

        i {
            color: $mobile-menu-active;
            font-size: 1.5rem;
        }
    }

    .navbar-nav {
        @include media-breakpoint-down(lg) {
            border-top: 1px $mobile-menu-active solid;
        }

        .nav-item {
            &.show {
                > a {
                    background: $mobile-menu-active;
                    color: white;

                    i {
                        color: white;

                        @include media-breakpoint-down(lg) {
                            &.fa-chevron-up {
                                display: inline;
                            }
                        }

                        &.fa-chevron-down {
                            display: none;
                        }
                    }
                }

                .dropdown-menu {
                    display: block;
                }
            }

            i {
                display: none;

                &.ico-desktop {
                    display: inline;
                    margin-right: .2rem;
                    color: black;
                    padding: .4rem;
                    // @include media-breakpoint-down(lg) {
                    //     display: none;
                    // }
                }

                &.fa-chevron-up {
                    display: none;
                }
            }

            a {
                padding: 1.7rem .7rem;
                line-height: 1.7rem;
                font-size: 0.875rem;

                @include media-breakpoint-down(lg) {
                    font-size: 1rem;
                }

                span.text {
                    position: relative;
                    top: -13px;

                    @include media-breakpoint-down(lg) {
                        left: 40px;
                        top: 0;
                    }
                }

                .icon {
                    margin-top: -3px;

                    @include media-breakpoint-down(lg) {
                        position: absolute;
                        top: 15px;
                    }
                }

                &:hover {
                    background: $mobile-menu-active;
                    color: white !important;
                }

                &.nav-link {
                    @include media-breakpoint-up(xl) {
                        height: 84px;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                a {
                    border-bottom: 1px $mobile-menu-active solid;
                    padding: 1rem 2rem;

                    i {
                        color: $mobile-menu-active;
                        margin-right: .7rem;
                        font-size: 1.2rem;
                        display: inline;
                    }
                }
            }

            .dropdown-menu {
                background: $mobile-menu-expanded;
                border: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

                @include media-breakpoint-down(lg) {
                    box-shadow: none;
                }

                a {
                    padding: 15px 42px;

                    @include media-breakpoint-down(lg) {
                        padding: 15px 15px 15px 65px;
                    }

                    @include media-breakpoint-up(lg) {
                        border-top: 1px gray solid;
                    }

                    &:hover {
                        background: $mobile-menu-active;
                        color: white !important;
                    }
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            &:hover {
                i, span {
                    border-color: white;
                    color: white;
                }

                .icon-employers {
                    background-image: url('../img/employers-icon-hover.svg');
                }

                .icon-members {
                    background-image: url('../img/members-icon-hover.svg');
                }
            }
        }
    }

    .search-icon {
        // border-right: 1px black solid;
        padding: 9px;
        //height:50px;
        @include media-breakpoint-down(lg) {
            box-shadow: none;
            margin: 1rem 2rem 1rem;
            border: none;
            padding: 10px 0 0;
            width: 51px;
        }

        i {
            color: black;
        }
    }

    .search-bar {
        background: white;
        padding: 1.5rem 0 1.5rem 1.5rem;
        left: 80px;
        position: absolute;
        width: 750px;
        top: 0;
        z-index: 3;

        @include media-breakpoint-down(lg) {
            margin: 1.5rem 2rem 1.5rem;
            border: none;
            box-shadow: none;
            padding: 0;
            position: relative;
            left: 0;
            width: auto;
        }

        @media (max-width: 1199.98px) and (orientation : landscape) {
            width: 50%;
        }

        input {
            width: 100%;
        }

        .autocomplete {
            border-color: black;
            border-radius: 1rem;
            height: 2.2rem;
            padding-left: 35px;

            &.change-border {
                border-radius: 1rem 1rem 0 0;
            }
        }

        .input-bg {
            position: absolute;
            // margin-left: 10px;
            color: black;
            left: 35px;
            top: 35px;

            @include media-breakpoint-down(lg) {
                left: 11px;
                top: 11px;
            }
        }

        .close-bar {
            position: absolute;
            right: 15px;
            top: 35px;
            color: black;
            cursor: pointer;

            @include media-breakpoint-down(lg) {
                right: 16px;
                top: 11px;
            }
        }
    }

    .follow-us {
        display: none;

        @include media-breakpoint-down(lg) {
            display: block;
            margin: 2rem 2rem 1rem;

            .social-icons {
                .btn {
                    background: black;

                    i {
                        color: white;
                    }
                }

                span {
                    width: 30px;
                    height: 30px;
                    font-size: 1.875rem;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

// Autocomplete Search bar

.ui-widget-content {
    z-index: 1050;

    &.ui-widget-content {
        border: 1px black solid;
        border-top: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}

.ui-menu .ui-menu-item-wrapper {
    font-size: .9rem;
    padding: .5rem 2.2rem;
}

.ui-menu .ui-menu-item-wrapper {
    &:hover {
        background: $tcdrs-blue !important;
    }
}

//Dropdown hover

// .navbar-nav .dropdown:hover>.dropdown-menu {
//     display:block;margin:0
//   }


// Google language
.google-language-selector {
    position: absolute;
    right: 2px;
    bottom: -2px;
    width: 100px;

    @include media-breakpoint-down(lg) {
        top: 90px;
        bottom: initial;
        right: 80px;
        left: initial;
        // z-index: 99;
        position: absolute;
    }
}

#google_translate_element {
    margin-right: 0px;
    padding: 0;
    position: relative;
    z-index: 9;
}

.goog-te-banner-frame.skiptranslate,
.goog-te-gadget-simple img,
img.goog-te-gadget-icon,
.goog-te-menu-value span {
    display: none !important;
}

.goog-te-menu-frame {
    box-shadow: none !important;
}

.goog-te-gadget-simple {
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36px' height='48px' viewBox='796 796 200 200' enable-background='new 796 796 200 200' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M973.166,818.5H818.833c-12.591,0-22.833,10.243-22.833,22.833v109.333c0,12.59,10.243,22.833,22.833,22.833h154.333 c12.59,0,22.834-10.243,22.834-22.833V841.333C996,828.743,985.756,818.5,973.166,818.5z M896,961.5h-77.167 c-5.973,0-10.833-4.859-10.833-10.833V841.333c0-5.974,4.86-10.833,10.833-10.833H896V961.5z M978.58,872.129 c-0.547,9.145-5.668,27.261-20.869,39.845c4.615,1.022,9.629,1.573,14.92,1.573v12c-10.551,0-20.238-1.919-28.469-5.325 c-7.689,3.301-16.969,5.325-28.125,5.325v-12c5.132,0,9.924-0.501,14.366-1.498c-8.412-7.016-13.382-16.311-13.382-26.78h11.999 c0,8.857,5.66,16.517,14.884,21.623c4.641-2.66,8.702-6.112,12.164-10.351c5.628-6.886,8.502-14.521,9.754-20.042h-49.785v-12 h22.297v-11.986h12V864.5h21.055c1.986,0,3.902,0.831,5.258,2.28C977.986,868.199,978.697,870.155,978.58,872.129z'/%3E%3Cg%3E%3Cg%3E%3Cpath d='M839.035,914.262l-4.45,11.258h-15.971l26.355-61.09h15.971l25.746,61.09h-16.583l-4.363-11.258H839.035z M852.475,879.876l-8.902,22.604h17.629L852.475,879.876z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 24px 36px;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 1.8;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-radius: 4px;
    width: 100px;
    position: relative;
    height: 36px;
    top: 2px;
}

.goog-te-gadget-simple::after {
    // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill='%23000000' height='14px' width='14px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 407.437 407.437' xml:space='preserve'%3E%3Cpolygon points='386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 '/%3E%3C/svg%3E");
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill='%23000000' height='10px' width='10px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 407.437 407.437' xml:space='preserve'%3E%3Cpolygon points='386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 '/%3E%3C/svg%3E");
    content: 'Language';
    position: absolute;
    top: 7px;
    left: 29px;
    background-repeat: no-repeat;
    background-position: right;
    font-family: Lato, Arial, Helvetica, sans-serif !important;
    padding-right: 18px;

    @include media-breakpoint-down(lg) {
        // content: '';
    }
}



.darkMode .goog-te-gadget-simple {
    background-color: transparent !important;
    background: url("data:image/svg+xml,%3Csvg viewBox=&#39;0 0 24 24&#39; xmlns=&#39;http://www.w3.org/2000/svg&#39;%3E%3Cg style=&#39;fill:none;stroke:%23fefefe;stroke-linecap:round;stroke-linejoin:round&#39;%3E%3Cpath d=&#39;M.5,2V18A1.5,1.5,0,0,0,2,19.5H17L10.5.5H2A1.5,1.5,0,0,0,.5,2Z&#39; /%3E%3Cpath d=&#39;M12,4.5H22A1.5,1.5,0,0,1,23.5,6V22A1.5,1.5,0,0,1,22,23.5H13.5l-1.5-4&#39; /%3E%3Cline x1=&#39;17&#39; x2=&#39;13.5&#39; y1=&#39;19.5&#39; y2=&#39;23.5&#39; /%3E%3Cline x1=&#39;14.5&#39; x2=&#39;21.5&#39; y1=&#39;10.5&#39; y2=&#39;10.5&#39; /%3E%3Cline x1=&#39;17.5&#39; x2=&#39;17.5&#39; y1=&#39;9.5&#39; y2=&#39;10.5&#39; /%3E%3Cpath d=&#39;M20,10.5c0,1.1-1.77,4.42-4,6&#39; /%3E%3Cpath d=&#39;M16,13c.54,1.33,4,4.5,4,4.5&#39; /%3E%3Cpath d=&#39;M10.1,7.46a4,4,0,1,0,1.4,3h-4&#39; /%3E%3C/g%3E%3C/svg%3E") center / 12px no-repeat;
    background-size: 20px 20px;
}

.headerIcon .gtWgt.gtHide {
    display: none;
}

.headerIcon .gtWgt .gtIcon {
    position: absolute;
    background-color: var(--header-bg);
}

.darkMode .headerIcon .gtWgt .gtIcon {
    background-color: var(--dark-bg);
}

.postComments.gtHide .gtWgt {
    display: none;
}

.postComments.gtHide .shrBtn {
    margin-right: 0;
}

.postComments .gtWgt .gtLoad {
    position: relative;
}

.postComments .gtWgt .gtIcon {
    position: absolute;
    background-color: var(--body-bg);
}

.postComments .gtWgt #google_translate_element {
    margin-right: 0;
}

.darkMode .postComments .gtWgt .gtIcon {
    background-color: var(--dark-bg);
}

.postComments .gtWgt .gtIcon .goog-te-gadget-simple {
    background-color: transparent !important;
    background-size: 18px 18px;
}

.postComments .gtWgt .gtIcon .goog-te-gadget-simple {
    background-color: transparent !important;
    background: url("data:image/svg+xml,%3Csvg viewBox=&#39;0 0 24 24&#39; xmlns=&#39;http://www.w3.org/2000/svg&#39;%3E%3Cg style=&#39;fill:none;stroke:%2348525c;stroke-linecap:round;stroke-linejoin:round&#39;%3E%3Cpath d=&#39;M.5,2V18A1.5,1.5,0,0,0,2,19.5H17L10.5.5H2A1.5,1.5,0,0,0,.5,2Z&#39; /%3E%3Cpath d=&#39;M12,4.5H22A1.5,1.5,0,0,1,23.5,6V22A1.5,1.5,0,0,1,22,23.5H13.5l-1.5-4&#39; /%3E%3Cline x1=&#39;17&#39; x2=&#39;13.5&#39; y1=&#39;19.5&#39; y2=&#39;23.5&#39; /%3E%3Cline x1=&#39;14.5&#39; x2=&#39;21.5&#39; y1=&#39;10.5&#39; y2=&#39;10.5&#39; /%3E%3Cline x1=&#39;17.5&#39; x2=&#39;17.5&#39; y1=&#39;9.5&#39; y2=&#39;10.5&#39; /%3E%3Cpath d=&#39;M20,10.5c0,1.1-1.77,4.42-4,6&#39; /%3E%3Cpath d=&#39;M16,13c.54,1.33,4,4.5,4,4.5&#39; /%3E%3Cpath d=&#39;M10.1,7.46a4,4,0,1,0,1.4,3h-4&#39; /%3E%3C/g%3E%3C/svg%3E") center / 12px no-repeat;
    background-size: 18px 18px;
}

.darkMode .postComments .gtWgt .gtIcon .goog-te-gadget-simple {
    background-color: transparent !important;
    background: url("data:image/svg+xml,%3Csvg viewBox=&#39;0 0 24 24&#39; xmlns=&#39;http://www.w3.org/2000/svg&#39;%3E%3Cg style=&#39;fill:none;stroke:%23d1d1d1;stroke-linecap:round;stroke-linejoin:round&#39;%3E%3Cpath d=&#39;M.5,2V18A1.5,1.5,0,0,0,2,19.5H17L10.5.5H2A1.5,1.5,0,0,0,.5,2Z&#39; /%3E%3Cpath d=&#39;M12,4.5H22A1.5,1.5,0,0,1,23.5,6V22A1.5,1.5,0,0,1,22,23.5H13.5l-1.5-4&#39; /%3E%3Cline x1=&#39;17&#39; x2=&#39;13.5&#39; y1=&#39;19.5&#39; y2=&#39;23.5&#39; /%3E%3Cline x1=&#39;14.5&#39; x2=&#39;21.5&#39; y1=&#39;10.5&#39; y2=&#39;10.5&#39; /%3E%3Cline x1=&#39;17.5&#39; x2=&#39;17.5&#39; y1=&#39;9.5&#39; y2=&#39;10.5&#39; /%3E%3Cpath d=&#39;M20,10.5c0,1.1-1.77,4.42-4,6&#39; /%3E%3Cpath d=&#39;M16,13c.54,1.33,4,4.5,4,4.5&#39; /%3E%3Cpath d=&#39;M10.1,7.46a4,4,0,1,0,1.4,3h-4&#39; /%3E%3C/g%3E%3C/svg%3E") center / 12px no-repeat;
    background-size: 18px 18px;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
    text-decoration: none;
    display: none;
}


/*hide iframe googe translate options: */
iframe [id*=".container"],
.skiptranslate iframe {
    visibility: hidden !important;
    display: none !important;
    position: absolute !important;
    top: -2000px !important;
    left: -2000px !important;
}

.translated-ltr body,
body {
    position: initial !important;
    top: 0 !important;
}
