// Library Serch Filters
.library-search {


    // Bootstrap Dropdown
    .dropdown-container {
        .dropdown {

            font-weight: 300;
            cursor: pointer;
            user-select: none;
            height: 60px;
            padding: 0;
            text-align: left;
            text-transform: inherit;
            margin-bottom: 22px;

            button {
                padding: 0 15px;
                letter-spacing: 0;
                text-transform: inherit;
                box-shadow: none;
                height: 100%;
                width: 100%;
                text-align: left;
                color: $paragraph;
                border: 1px solid $graphite;
                border-radius: 28px;
                background: white;
                font-weight: 300;

                &::after {
                    position: absolute;
                    content: "";
                    top: 12px;
                    right: 10px;
                    border: 6px solid transparent;
                    color: $tcdrs-blue;
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: 900;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: "\e906";
                    font-size: 1.5rem;
                    transform: rotate(-90deg);

                    @include media-breakpoint-down(sm) {
                        top: 9px;
                    }
                }

                span {
                    position: relative;
                    width: 12px;
                    height: 12px;
                    border-radius: 12px;
                    border: 1px white solid;
                    display: inline-block;
                    margin-right: 10px;
                    margin-left: 5px;
                }

                img {
                    margin-right: 15px;
                    width: 35px;
                }
            }

            &.show button {
                border-radius: 28px 28px 0 0;
            }

            /*point the arrow upwards when the select box is open (active):*/
            &.show button:after {
                transform: rotate(90deg);
            }

            @include media-breakpoint-down(sm) {
                height: 50px;
                padding: 0;
                line-height: 24px;
                margin-bottom: $mobile-gutter;
            }

            .dropdown-menu {
                position: absolute;
                background-color: white;
                top: 100%;
                left: 0;
                right: 0;
                z-index: 99;
                border: 1px solid $graphite;
                border-top: none;
                border-radius: 0 0 28px 28px;
                overflow: hidden;
                padding: 0;
                margin: 0;

                -webkit-box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);
                -moz-box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);
                box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);

                li {
                    text-align: left;
                    font-size: 1rem;
                    font-weight: 300;
                    letter-spacing: 0.5px;
                    line-height: 25px;
                    border-bottom: 1px solid $graphite;
                    position: relative;

                    &:hover {
                        background-color: $tcdrs-blue;

                        a {
                            color: white;
                        }
                    }

                    &:last-of-type {
                        border: none;
                    }

                    span {
                        border-radius: 12px;
                        border: 1px white solid;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 20px;
                        top: 23px;
                    }

                    &:nth-child(1) span {
                        border: none;
                    }

                    &:hover {
                        filter: invert(0%);

                        img {
                            filter: brightness(0) invert(1);
                            -webkit-filter: brightness(0) invert(1);
                        }
                    }

                    img {
                        top: 10px;
                        left: 15px;
                        position: absolute;
                        width: 35px;
                    }

                    a {
                        padding: 15px 15px 15px 65px;
                        display: block;
                        color: $paragraph;

                        &:first-child {
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .topic-filter {
            .dropdown .dropdown-menu li a {
                padding: 15px 15px 15px 42px;
            }
        }
    }



    #library-search-icon{
        margin-top: 13px;
        text-decoration: none !important;
        @include media-breakpoint-down(sm) {
            margin-bottom: 13px;
        
        }

        .text-icon-search {
            display: none;
            @include media-breakpoint-down(sm) {
                display: inline-block;
                position: relative;
                top: -10px;
                margin-left: 10px;
                font-weight: 400;
                color: black;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
    #librarySearchInput{
        margin-bottom: 22px;
    }
    .input-field-search-container{
        position: relative;
        display: none;
        .close-bar{
            position: absolute;
            right: 20px;
            top: 22px;
            color: black;
            cursor: pointer;
            @include media-breakpoint-down(xs) {
                //display: none;
                top: 17px;
            }
        }
    }

}

// Library Search Results

.library-search-results {
    margin-bottom: 40px;
    @include media-breakpoint-only(md) {
        margin-bottom: 20px;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 10px!important;
        padding-bottom: 0!important;
    }

    .card-img,
    .card-img-top {
        max-height: 176px;
        height: 176px;
    }

    .card{
        position: relative;
        .card-date {
            height: 28px;
        }
        // p{
        //     margin-bottom: 55px;
        // }
        // .btn.with-arrow{
        //     position: absolute;
        //     bottom: 32px;
        // }
    }
}

.library-videos{
    .load-more{
        margin-bottom: 100px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
    }
}

//results title
.results-title{
    .text-center{
        @include media-breakpoint-down(sm) {
            margin-bottom: 32px !important;
        }
        h3.h3{
            color: $graphite;
        }
        h5,h3{
            @include media-breakpoint-down(sm) {
            font-size: 1.375rem;
            font-weight: 400;
            line-height: 2rem;
            }
        }
    }
    .reset-filters{
        display: inline; 
        top: -5px; 
        position: relative; 
        margin-left: 10px;
    }
}




//
.reset-filters {
    color: #6D7278;
    text-transform: uppercase;
    text-align: right;

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    cursor:pointer;

    i {
        color: #6D7278;
        font-size: 2rem;
        margin-top: 1rem;
    }

    a {
        color: #6D7278;
        font-weight: 500;
        display: inline-block;
        margin-top: 15px;
        letter-spacing: .1rem;
        cursor: pointer;

        img {
            margin-right: 7px;
        }
    }
}

.featured-publications .no-results{
    text-align: center;
    h4{
        color: $tcdrs-blue;
        font-size: 2.1875rem;
        font-weight: 400;
        line-height: 2.8125rem;
        text-transform: none;        

        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
            letter-spacing: 1.25px;
            line-height: 1.5625rem;
        }
    }
    p{
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
    ul{
        text-align: left;
        @include media-breakpoint-down(sm) {
            padding-left: 20px;
        }
    }
}