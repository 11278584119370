.library-post.side-by-side {
    .media-container {
        position: relative;
        padding: 60px 0;
        overflow: visible;

        .img-fluid {
            max-width: 680px;
            height: 315px;

            @include media-breakpoint-down(md) {
                height: auto;
            }

            -webkit-box-shadow: 14px 14px 0px -3px #05347a;
            -moz-box-shadow: 14px 14px 0px -3px #05347a;
            box-shadow: 14px 14px 0px -3px #05347a;
        }

        span.icon-PlayButton-1 {
            background: transparent;
            color: #05347A;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            border: none;
            font-size: 3.125rem;
            display: block;
        }
    }

    h5 {
        font-weight: 500;
        font-size: 1.7rem;
        margin-top: .75rem !important;
        margin-bottom: .75rem;

        @include media-breakpoint-down(md) {
            font-size: 1.55rem;
            margin-top: 2.5rem !important;
        }
    }

    ul li {
        margin-bottom: .75rem;

        strong {
            font-weight: 700;
            letter-spacing: -.25px;
        }
    }

    a {
        // font-size: 1.15rem;

        @include media-breakpoint-down(md) {
            &.underline {
                display: block;
                margin-bottom: 1rem;
                font-size: 1.1rem;
            }
        }
    }

    .right-rail {
        h5 {
            margin-top: 0;
            font-size: 1.5rem;
        }

        .box-shadow {
            padding: 40px;
        }

        .gray-box {
            background: #F0F0F0;
        }

        .mt-5 {
            margin-top: 3.75rem !important;

            @include media-breakpoint-down(md) {
                margin-top: 2.75rem !important;
            }
        }

        .btn.no-background {
            margin-top: 1.65rem !important;
            text-align: left;
        }
        a{
            font-size: 1.15rem;
        }
    }

    h2 {
        color: $tcdrs-blue !important;
    }

    h1 {
        color: $tcdrs-blue !important;
        font-size: 3.4375rem;
        line-height: 3.4375rem;
    }
}

// Library Videos Styles

.library-videos {
    @include media-breakpoint-down(sm) {
        .carousel {
            margin-bottom: 0 !important;

            .slick-dots {
                display: none !important;
            }

            .btn.with-arrow {
                display: block;
            }

            .slick-track {
                display: flex !important;
            }

            .slick-slide {
                height: auto;
                margin-bottom: 20px;
            }
        }
    }

    .card {
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

        .video-icon {
            background: white;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            right: 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }

            img {
                margin-left: 7px;
                margin-top: 7px;
            }
        }

        .card-category {
            &.account-update {
                color: $tcdrs-au;
                border-color: $tcdrs-au;
                text-transform: initial;
            }

            &.benefits {
                color: $tcdrs-b;
                border-color: $tcdrs-b;
                text-transform: initial;
            }

            &.community {
                color: $tcdrs-c;
                border-color: $tcdrs-c;
                text-transform: initial;
            }
        }

    }

    h3 {
        @include media-breakpoint-down(md) {
            margin-top: 2rem !important;
            font-size: 2.188rem;
            margin-bottom: 1.875rem;
        }
    }

    h5 {
        @include media-breakpoint-down(md) {
            margin-bottom: 0 !important;
        }
    }

    &.featured-publications {
        .carousel:last-child {
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
        }

        .text-center:last-child .btn {
            margin-top: 80px;
            @include media-breakpoint-down(sm) {
                margin-top: 30px;
            }
            @include media-breakpoint-only(md) {
                margin-top: 60px;
            }
        }

        // @include media-breakpoint-up(lg) {
        //     padding: 25px 0!important;
        // }

        // @include media-breakpoint-down(sm) {
        //     padding: 40px 0 10px!important;
        // }
    }
}