// Key Dates Richtext
.key-dates {
    margin-top: 40px;
    margin-bottom: 0px;
    
    @include media-breakpoint-up(md) {
        margin-top: 60px;
        margin-bottom: 20px;
        .right-card-margin{
            margin-top: 150px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 100px;
        margin-bottom: 50px;
        .right-card-margin{
            margin-top: 150px;
        }
    }


    .card {
        border: 2px $tcdrs-blue solid;
        border-radius: 10px;
        padding: 45px;
        margin-bottom:50px;
        margin-right: 56px;
        @include media-breakpoint-down(md) {
            padding: 40px 24px 24px;
            margin-right:0; 
            margin-bottom:40px;
            h4,h5{
                text-align: center;
            }
        }
        .eyebrow{
            position: absolute;
            top: -14px;
            left: 20px;
            background: white;
            padding: 0 25px;
        }
        ul{
            li{
                margin-bottom: 10px;
            }
        }
        strong{
            font-size:1rem;
            color: $tcdrs-blue;
            font-weight: bold;
        }
        .highlight{
            background-color: $carousel-bg;
            border-radius: 10px;
            padding:40px 36px;
            p{
                font-size: 1rem;
                margin:0;
            }
        }
        .circle-icon{
            @include media-breakpoint-down(md) {
                display:none;
            }
            // border:2px solid $tcdrs-blue;
            // border-radius: 67px;
            // width:67px;
            // height:67px;
            position: absolute;
            right:-35px;
            bottom:46px;
            // padding: 12px;
            background: white;
            img{
                width:70px;
                height:70px;
            }
        }
    }
}

*::-ms-backdrop, .key-dates .card .btn {
    min-height: 60px;
    @include media-breakpoint-down(md) {
        width: 100%!important;
        min-width: 100%!important;
    }
}

// Join TCDRS Richtext

.join-tcdrs{
    background-color: $tcdrs-blue;
    // padding:100px 0;
    img{
        width:100%;
        // height:397px;
        // @include media-breakpoint-down(md) {
        //     width:100%;
        // }
    }
    h2,h4,p{
        color:white;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    .text{
        padding-left: 122px;
        margin-top: 40px;
        @include media-breakpoint-down(md) {
            padding-left: initial;
        }
        img{
            width: auto;
            @include media-breakpoint-down(md) {
                width: 55px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 10px!important;
                width: 85px;
            }
        }
        // p{
        //     @include media-breakpoint-down(md) {
        //         text-align: left;
        //     }
        // }
    }
    a{
        color:$tcdrs-yellow;
    }
}

// Library Teaser
.library-teaser {
    background-color: $light-blue;
    // padding: 100px 0;
    .top{
        h2,p{
            color:white
        }
        p{
            font-size: 1.5rem;
        }
    }
    .card{
        background-color: white;
        border-radius: 10px;
        padding: 2.7rem;
        border:none;
        height:100%;
        a.no-background{
            display: inline-flex;
        }
        @include media-breakpoint-down(md) {
            // margin:14px $mobile-gutter;
            margin-bottom:20px;
            height:auto;
        }
    }
}