.plan-finder-component{

    padding: 100px 0;
    @include media-breakpoint-down(md) {
        padding: 40px 0;
    }

    .title-block{
        margin-bottom: 40px;
    }

    #plan-finder-table.table{
        width: 100% !important; //search documentation for responsive table mode
        thead > tr th{
            border-top: none;
            border-bottom: 3px solid $tcdrs-blue;
            font-family: Lato;
            font-size: 1.125rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 28px;
            text-transform: uppercase;
        }

        tbody{
            tr{

                &.details-row{
                    border: 1px solid $graphite;
                    opacity: 1 !important;

                    > td{padding:0;}

                    .col-md-6.text-center{
                        padding: 20px 100px;

                        @include media-breakpoint-down(md) {
                            padding: 0 24px;
                        }
                        @include media-breakpoint-down(sm) {
                            padding: 0 12px;
                        }
                    }

                    .plan-details{
                        padding: 40px 15px 10px;
                        margin: 0 !important;
                    }
                    .retirement-eligibility{
                        background: $tcdrs-lblue;
                        padding: 30px 15px;
                        margin: 0 !important;
                    }
                }

                td{
        
                        border-bottom: 1px solid $graphite;
                        padding: 16px .75rem;
                        vertical-align: middle;
                        font-family: Lato;
                        font-size: 1rem;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        line-height: 25px;

                        &.details-control{
                            text-align: center;
                            cursor: pointer;
                            
                            @include media-breakpoint-down(sm) {
                                width: 15% !important;
                            }
                        }

                        &.details-control::before {
                            content: "\e907";
                            color: $tcdrs-blue;
                            display: inline-block;
                            font-family: 'icomoon' !important;
                            speak: none;
                            font-style: normal;
                            font-weight: 700;
                            font-variant: normal;
                            font-size: 1.438rem;                                                 
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            cursor: pointer;
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            transform: rotate(90deg);

                            

                        }

                        &.dataTables_empty{
                            display: none;
                        }
                }

                &.shown{
                    td{
                        background: $tcdrs-blue;
                        color: white;
                        font-weight: bold;

                        &.details-control::before {
                            color: white;
                            -webkit-transform: rotate(-90deg);
                            -moz-transform: rotate(-90deg);
                            -o-transform: rotate(-90deg);
                            -ms-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                            display: inline-block;
                        }
                    }
                }

                
            }
        }

        &.last-row-partial{
            tbody{
                tr{
                    &:last-child{
                        opacity: 0.2;
                        td{
                            border-bottom-color: #d2d2d2;
                        }
                    }
                }
            }
        }
    }

    #plan-finder-table_filter{
        margin-bottom: 60px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }

        label{
            //display: none;
            width: 100%;
            margin: 0;

            input{            
                display: block;
                width: 100%;
                height: 56px;
                padding: 12px 20px;
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.5;
                color: $paragraph;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid black;
                border-radius: 28px;                    
                background-image: url(../img/search-icon-02.svg);
                background-position: 20px center;
                background-repeat: no-repeat;
                padding-left: 50px;

                &:focus{
                    outline: none;
                }
            }
        }
      
    }

    #search-results-not-found{
        display: none;

        .text-left{
            margin-top: 60px;
            @include media-breakpoint-down(sm) {
                margin-top: 20px;
            }
            ul li{ margin-bottom: 10px;}
        }
    }

    .dataTables_paginate{
        display: none !important;
    }


}

//column sorting icons for datatables
table.dataTable thead .sorting, 
table.dataTable thead .sorting_asc, 
table.dataTable thead .sorting_desc, 
table.dataTable thead .sorting_asc_disabled, 
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right;
}
table.dataTable thead .sorting {
    background-image: url(../img/sort_both.png);
}
table.dataTable thead .sorting_desc {
    background-image: url(../img/sort_desc.png);
}

table.dataTable thead .sorting_asc {
    background-image: url(../img/sort_asc.png);
}

