.investment-assets {
    .tabs-component {
        padding: 100px 0;

        @include media-breakpoint-down(md) {
            padding: 50px 0;
        }
    }

    .media-container.video {
        padding: 10px 0 40px 0;
        @include media-breakpoint-down(md) {
            padding: 10px 24px 40px 0;
        }
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0 !important;
            margin-top: 2.5rem;
        }

        
        .img-fluid {
            left: 0;
            width: 100%;
            max-width: 588px;
            position: static;
            -webkit-box-shadow: 14px 14px 0px -3px $tcdrs-blue;
            -moz-box-shadow: 14px 14px 0px -3px $tcdrs-blue;
            box-shadow: 14px 14px 0px -3px $tcdrs-blue;
            margin: 0;
            top: 0;
            transform: none;
            // @include media-breakpoint-down(sm) {
            //     float:none;
            // }
        }

        a{
            position: relative;
            display: block;
        }
        span.icon-PlayButton-1 {
            background: transparent;
            color: #05347A;
            position: absolute;    
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            border: none;
            font-size: 3.125rem;
            display: block;
        }

        i.fas {
            background: #fff;
            color: #05347A;
            display: inline-block;
            padding: 15px 17px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            font-size: 2rem;
            border: none;

            &::before {
                position: relative;
                left: 3px;
            }
        }
    }

    [class*="col-"].table-responsive {
        margin-left: 0 !important;

        table {

            td,
            th {
                text-align: left;
                width: 40%;
                padding: 14px 12px;
                vertical-align: middle;

                @include media-breakpoint-down(sm) {
                    width: 50% !important;
                    min-width: 225px;
                    font-size: 1rem;
                    padding: 14px 24px;

                    &:first-child {
                        padding: 14px 10px 14px 0 !important;
                    }

                    &:last-child {
                        min-width: 425px;
                    }
                }

                &:nth-child(2) {
                    width: 20%;
                }

                p {
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    padding: 0;
                    margin: 0;
                    a{
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
            }

            th {
                border-bottom: 1px black solid;
                font-weight: 500;
            }
        }
    }

    // Circle Tabs styles

    .tabs-component {
        @include media-breakpoint-down(sm) {
            >div {
                margin-bottom: 0 !important;
            }
        }

        .nav-pills {
            @include media-breakpoint-down(sm) {
                overflow-x: auto;
                overflow-y: hidden;
                display: -webkit-box;
                display: -moz-box;
                flex-wrap: inherit;

                >a {
                    float: none;
                }
            }

            .nav-item {
                max-width: initial !important;
            }

            .nav-link {
                margin: 0;
                padding: 0;
                line-height: initial !important;
                box-shadow: none;
                -webkit-box-shadow: none;
                letter-spacing: 0;
                //default override
                display: inherit;

                @include media-breakpoint-down(md) {
                    padding: 7px 3px;
                    margin-right: 24px;
                    text-align: left;
                    height: auto;

                    &.active strong {
                        color: $tcdrs-blue;
                        font-weight: 600;
                    }

                    strong {
                        color: black;
                        font-weight: 300;
                        display: block;
                        text-align:center;
                    }
                }


                &.active {
                    @include media-breakpoint-up(lg) {
                        // height:auto;s
                        height: 215px;
                        padding-bottom: 7px;
                    }
                }
            }

            input {
                font-weight: 300 !important;
                font-family: 'Lato', sans-serif !important;
                font-size: 2.85rem !important;
                outline: none !important;
                cursor: pointer;
                top: -17px;

                // @include media-breakpoint-down(lg) {
                //     display: none;
                // }

                // @include media-breakpoint-only(lg) {
                //     display: block !important;
                // }
            }

            strong {
                @include media-breakpoint-up(lg) {
                    // font-size: 1rem;
                    // display: list-item;
                    // margin-top: -102px;
                    // height: 102px;
                    // letter-spacing: -.25px;
                    font-size: 1rem;
                    display: list-item;
                    margin-top: -198px;
                    height: auto;
                    letter-spacing: -.25px;
                    width: 60%;
                    margin: 0 auto;
                    top: -102px;
                    position: relative;

                    @media screen and (-ms-high-contrast: active),
                    (-ms-high-contrast: none) {
                        margin-top: -31px;
                        height: 100px;
                        line-height: 1.15rem;
                    }
                }
            }

            canvas,
            input {
                @include media-breakpoint-only(lg) {
                    display: initial !important;
                    max-width: 152px;
                }

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            li.nav-item:last-child{
                a{
                    margin-right: 0 !important;
                }
            }

        }
    }

    .tab-content {
        .tab-pane {
            // .mobile-circle {
            //     display: none;
            // }

            @include media-breakpoint-down(md) {
                .mobile-circle {
                    // display: block;
                    text-align: center;
                    position: relative;

                    input {
                        font-weight: 300 !important;
                        font-family: 'Lato', sans-serif !important;
                        font-size: 2.85rem !important;
                        outline: none !important;
                        cursor: pointer;
                        top: -17px;
                    }

                    strong {
                        font-size: 1rem;
                        display: list-item;
                        margin-top: -106px;
                        height: 106px;
                        letter-spacing: -.25px;
                        color: $tcdrs-blue;
                        list-style: none;
                        // width: 28%;
                        width:90px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                h4,
                p,
                strong {
                    text-align: center;
                }

                h4 {
                    font-size: 2.2rem;
                    margin-bottom: 1.2rem;
                    letter-spacing: 0;
                    line-height: 2.2rem;
                }

                strong {
                    //color: $tcdrs-red;
                    display: block;
                }

                ul li strong{
                    display: inline-block;
                }

                .col-md-5,
                .col-md-10,
                .table-responsive,
                .d-block {
                    padding: 0 !important;
                }

                // .col-md-10.text-center {
                //     margin-top: -20px !important;
                // }
            }
        }
    }

    .subclass-mobile {
        margin-top: 42px !important;

        .col-md-12 {
            border-top: 1px black solid;
            padding: 15px 0;

            &:last-child {
                border-bottom: 1px gray solid;
            }
            .col{
                padding: 0;
            }
        }

        strong {
            color: black !important;
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: .5px;
            margin-bottom: 10px;
        }

        p {
            font-size: 1rem;
            letter-spacing: .5px;
            margin-bottom: 0;
        }
    }
}