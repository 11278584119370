.tabs-component{
    padding: 60px 0 0 0;
    @include media-breakpoint-down(md) {
        padding: 40px 0 0 0;
    }
    .nav-pills{
        border-bottom: 1px solid $tcdrs-lgrey;
        .nav-item{
            @include media-breakpoint-down(sm) {
                max-width: 33.33%;
            }
        }
        .nav-link{
            background: transparent;
            padding: 20px 24px;
            font-size: 1.125rem;
            font-weight: 400;
            color: $tcdrs-lgrey;
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
            margin: 0 0%;

            //center vertical
            display: inline-block;
            
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                //align vertical
                display: flex;
                padding: 0 5px;
                margin: 0 18%;
            }
            
            &.active{
                border-bottom: 5px solid $tcdrs-red;
                color: $tcdrs-blue;
                
                border-radius: 10px 10px 0 0;
                -webkit-box-shadow: 0px 0px 20px 4px rgba(109,114,120,0.15);
                -moz-box-shadow: 0px 0px 20px 4px rgba(109,114,120,0.15);
                box-shadow: 0px 0px 20px 4px rgba(109,114,120,0.15);
                @include media-breakpoint-down(sm) {
                    margin: 0 0px; //
                }
                img, i{
                    color: $tcdrs-blue;
                    filter: none;
                    opacity: 1;
                }
            }

            img, i{
                color: $tcdrs-lgrey;
                //margin: 0;
                //position: relative;
                //top: 25%;
                //left: 0;
                margin-right: 5px;
                //transform: translate(0, -25%);
                //font-size: 40px;
                display: inline-block;
                height: 40px;
                filter: grayscale(100%);
                opacity: 0.6;

                @include media-breakpoint-down(md) {
                    display: block;
                    position: relative; 
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto 10px;                    
                    transform: none;
                }
            }

            @include media-breakpoint-down(md) {
                margin: 0 5%;
                font-size: 1rem;
                line-height: initial;
            }
            @include media-breakpoint-down(sm) {
                height: 100%;
                line-height: initial;
                padding: 12px 0px;
                word-break: break-word;
                margin: 0 0px;//
                width: 100%;
            }
        }
    }

    .nav-item {
        @include media-breakpoint-down(sm) {
           
        }
        
    }

    .tab-pane{
        &.active{
            .side-by-side{
                background: transparent;
            }
        }
    }

    .side-by-side {
        .text-container {
            padding: 60px 0 60px 15%;
            @include media-breakpoint-down(sm) {
                padding: 36px 0px 0px;
                text-align: left;
                a.btn {
                    display: block;
                }
            }
        }
        .col-md-12{
            .text-container {
                padding: 60px 0 60px 0;
            }
        }
        .media-container {
            overflow: hidden;
            padding: 120px 0px;
            @include media-breakpoint-down(sm) {
                padding: 40px 0px 0 0;
            }

            &.video{
                padding: 120px 10px 120px 0;
                @include media-breakpoint-down(sm) {
                    padding: 40px 0px 0 0;              
                }
            }
            
        }
    }
}