.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.p-125 {
  padding: 125px;
}

.separator {
  clear: both;
  margin: 1rem 0;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.no-border {
  border: none !important;
}

.reset-padding {
  padding: 0;
}

.reset-margin {
  margin: 0;
}

// Component Paddings
.component-p-100 {
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }
}

.component-p-50 {
  padding: 50px 0;

  @include media-breakpoint-down(md) {
    padding: 25px 0;
  }
}

// Box shadow class
.box-shadow {
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  // height: 100%;
  padding: 40px 55px;

  &.p-100 {
    padding: 100px;

    @include media-breakpoint-down(md) {
      padding: 50px 30px;
    }
  }

  &.p-90 {
    padding: 90px;

    @include media-breakpoint-down(md) {
      padding: 50px 30px;
    }
  }
}

.box-shadow-none {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.shadow-in-box-lg {
  -webkit-box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);
  -moz-box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);
  box-shadow: 0 0 20px 4px rgba(109, 114, 120, 0.15);
}

.shadow-in-box-sm {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.line-height-2 {
  line-height: 2.2rem;
}

.cursor-pointer {
  cursor: pointer;
}


//video style for anchor, when used in richtext editor
a.video-with-play-icon {
  text-decoration: none;
}

a.video-with-play-icon::before {
  content: "\e913";
  color: rgb(0, 0, 0);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: #05347A;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: none;
  font-size: 3.125rem;
  display: block;
}


.container .component-title {
  @include media-breakpoint-down(md) {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
    padding: 0 30px !important;
  }
}

.mt-60 {
  margin-top: 60px !important;

  @include media-breakpoint-down(sm) {
    margin-top: 10px !important;
  }
}

.mb-60 {
  margin-bottom: 60px !important;
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px !important;
  }
}

.mt-100 {
  margin-top: 100px !important;

  @include media-breakpoint-down(sm) {
    margin-top: 40px !important;
  }
}
.mb-100 {
  margin-bottom: 100px !important;
  @include media-breakpoint-down(sm) {
    margin-bottom: 40px !important;
  }
}