/*
main container

    -tabs container
        - annualized returns: chart/table
        - quarterly returns
        - year to date returns

*/




.investment-results{

    #investmentsTabsContainer{
        margin-bottom: 60px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
    }

    .investment-results-bottom-cta{
        margin-bottom: 100px;
        
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
        .btn{
            min-width: 310px;
        }
        .btn:first-child{
            margin-right: 30px;
            @include media-breakpoint-down(sm) {
                margin-right: 0px;
                margin-bottom: 30px;
            }
        }
        
    }

    &.tabs-component .nav-pills .nav-link.active{
        font-weight: 700;
    }

    .annualized-returns-error-container,
    .year-to-date-returns-error-container{
        padding: 60px 50px 100px 50px;
        @include media-breakpoint-down(md) {
            padding: 40px 50px 100px 50px;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 23px 30px 23px;
        }  
    }

    .annualized-returns-container{
        .graph-controls-container{
            padding: 35px;
            text-align: right;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
        .graph-controls{
            border-radius: 20px;
            border: 0.8px solid $graphite;
    
            label.btn.btn-secondary {
                color: #272727;
                font-family: Lato;
                font-size: 0.9rem;
                letter-spacing: 0.4px;
                line-height: 22.4px;
                text-align: center;
                padding: 8px 28px;
                text-transform: capitalize;
                border-radius: 20px;
                border: none;
                background: white;
                &.active {
                    color: white;
                    background: $tcdrs-red;
                    border-radius: 20px;
                    z-index: 10;
                    outline-style:none;
                    box-shadow:none;
                }
            }
        }

        .graph-presets{
            margin-top: 60px;
            @include media-breakpoint-down(sm) {
                margin-top: 30px;
            }
            .preset-longterm{            
                display: inline-block;
                line-height: 37px;
                margin-right: 30px;

                color: $paragraph;
                font-size: 1.125rem;
                font-weight: 300;
                letter-spacing: 0.5px;

                @include media-breakpoint-down(sm) {
                    display: block;
                    margin-bottom: 30px;
                }
                span{
                    display: block;
                    float: left;
                    margin-right: 10px;
                    width: 37px;
                    height: 37px;
                    background-color: $tcdrs-blue;
                }
            }
            .preset-benchmark{
                display: inline-block;
                line-height: 37px;

                color: $paragraph;
                font-size: 1.125rem;
                font-weight: 300;
                letter-spacing: 0.5px;

                @include media-breakpoint-down(sm) {
                    display: block;
                }
                span{
                    display: block;
                    float: left;
                    margin-right: 10px;
                    width: 37px;
                    height: 37px;
                    background-color: $tcdrs-yellow;
                }
            }
        }

        .mobile-tooltip-annualized-chart{
            display: none;
            @include media-breakpoint-down(xs) {
                display: block;
            }

            .annualized-tooltip{
                padding: 10px;
                margin-bottom: 10px;
                position: relative;
                a{
                    position: absolute;
                    right: 7px;
                    top: 6px;
                    font-size: 0.75rem;
                }
                p{
                    margin: 0;
                    font-size: 0.875rem;
                }

            }
        }
    }

   

    .box-shadow{

        border-radius: 10px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        -moz-box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        padding: 60px 0px 60px 0px;
        @include media-breakpoint-down(md) {
            padding: 40px 50px 60px 50px;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 23px 30px 23px;
        }

        
    }

    //1st TAB table
    #annualized-table{
        padding-left: 0;
        padding-right: 0;
        overflow-x: auto;

        @include media-breakpoint-down(md) {
            padding: 40px 50px 100px 50px;
        }
        @include media-breakpoint-down(sm) {
            padding: 30px 23px 30px 23px;
        }

        .table{
            font-size: 0.875rem;
            font-weight: 300;
            margin-bottom: 30px;
            //line-height: 18px;
       
            @include media-breakpoint-down(md) {        
                margin-top: -20px;
            }

            tr{
                &:hover{
                    background-color: #f0f0f0;
                    td:nth-child(1) {            
                        background-color: #f0f0f0;
                    } 
                }
            }


            tr.main-header{
                //border-bottom: 3px solid $tcdrs-blue;
                border-bottom: 3px solid $tcdrs-blue !important;
                td{
                    border-top: none;
                    border-bottom: none;
                    vertical-align: bottom;

                    @include media-breakpoint-down(md) {                           
                        font-size: 1rem;
                        word-break: normal;
                        min-width: 90px;
                    }

                    &:last-child{
                        //display: table-caption; //50146 ie11 issue
                        text-align: center;
                    }
                    &:first-child{
                        width: 210px;
                        height: 80px;
                        @include media-breakpoint-down(sm) {
                            width: 149px;
                        }
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                }                    
            }

            tr.class-header{

                td:first-child{                        
                        
                    display: flex;
                    align-items: center;
                    justify-content: left;

                    font-weight: 600;
                    color: $tcdrs-blue;
                    strong{
                        font-weight: 600; 
                    }
                }
                
                &.open{
                    color: white;
                    background-color: $tcdrs-blue;

                    span.knob{
                        &::before{
                            transform: translate(-0%, -50%) rotate(90deg);
                            color: white;
                        }
                    }

                    td{
                        color: white;
                        background-color: $tcdrs-blue;
                    }
                }                
                
            }

            tr{
                td{
                    border-top: none;
                    border-bottom: none;
                    &:first-child{
                        width: 210px;                        
                        @include media-breakpoint-down(sm) {
                            width: 149px;
                        }
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                }

                &:nth-child(2) {
                    td{
                        //border-top: 3px solid $tcdrs-blue !important;
                    }
                    td:first-child{
                        //top: -1px;
                    }
                }
            }
           
            th, td {
                padding: 16px 12px;
                vertical-align: top;
                border-top: none;
                border-bottom: none;
                //border-top: 1px solid $graphite;

                //responsive shadow
                &:first-child{
                    @include media-breakpoint-down(md) {                        
                    -webkit-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                    -moz-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                    box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                    }
                }
                
            }

            tr{
                border-bottom: 1px solid $graphite;
                td{
                
                    &:first-child{
                        padding-left: 34px;
                    }
                    span.knob{
                        cursor: pointer;
                        position: relative;
                        display: inline-block;
                        &::before{
                            position: absolute;
                            content: "";
                            top: 0px;
                            left: 0px;
                            border: 6px solid transparent;
                            color: #05347A;
                            font-family: 'icomoon' !important;
                            speak: none;
                            font-style: normal;
                            font-weight: 900;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: "\e906";
                            font-size: 1.063rem;
                            

                            //center
                            margin: 0;              
                            position: absolute;
                            top: 50%;
                            left: -31px;               
                            transform: translate(-0%, -50%) rotate(-90deg);
                        }                      
                    }
                    
                }

               

            }

    
            strong{
                font-weight: 600;
            }


        }
    }


    //2nd TAB
    .quarterly-returns-container{
        margin-top: 55px;
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }

        #quarterly-table{
            padding-left: 0;
            padding-right: 0;
            overflow-x: auto;
    
            @include media-breakpoint-down(md) {
                padding: 40px 50px 100px 50px;
            }
            @include media-breakpoint-down(sm) {
                padding: 30px 23px 30px 23px;
            }

            .table{
                font-size: 0.875rem;
                font-weight: 300;
                margin-bottom: 30px;
                //line-height: 18px;
           
                @include media-breakpoint-down(md) {        
                    margin-top: -20px;
                }
    
                tr.main-header{
                    //border-bottom: 3px solid $tcdrs-blue;
                    border-bottom: 3px solid $tcdrs-blue !important;
                    td{
                        border-top: none;
                        border-bottom: none;
                        vertical-align: bottom;
    
                        @include media-breakpoint-down(md) {                           
                            font-size: 1rem;
                            word-break: normal;
                            min-width: 90px;
                        }
    
                        &:last-child{                            
                            //text-align: center;
                            //display: table-caption;
                            //display: table-cell; //ie11 fix
                        }
                        &:first-child{
                            width: 210px;
                            height: 80px;
                            @include media-breakpoint-down(sm) {
                                width: 149px;
                            }
                        }
                        &:nth-child(2) {
                            width: 150px;
                        }
                    }                    
                }
              
    
                tr{
                    border-bottom: 1px solid $graphite;
                    td{
                    
                        padding: 16px 12px;
                        vertical-align: top;
                        border-top: none;
                        border-bottom: none;
      
                        //border-top: 1px solid $graphite;
    
                        //responsive shadow
                        &:first-child{
                            padding-left: 10px;
                            width: 210px;                        
                            
                            @include media-breakpoint-down(md) {    
                                padding-left: 0px;                    
                                -webkit-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                                -moz-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                                box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                            }
                            @include media-breakpoint-down(sm) {
                                width: 149px;
                            }
                        }
                        
                        
                    }
    
                    &.indent{
                        td:first-child{
                            padding-left: 25px !important;
                            @include media-breakpoint-down(md) {  
                                padding-left: 15px !important;
                            }
                        }
                    }
                    &.bold{
                        td{
                            font-weight: 600;
                        }
                    }
    
                }
    
        
                strong{
                    font-weight: 600;
                }
            }
        }    
    }

    //3rd TAB
    .year-to-date-returns-container{
        margin-top: 55px;
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
    }


    .top-section-tab-content{
        padding: 0px 50px 60px 50px;
        @include media-breakpoint-down(md) {
            padding: 0px 0 20px 0;
            .toggle-content{
                text-align: center;
            }
        }
        @include media-breakpoint-down(md) {
            padding: 0px 0 20px 0 !important;
        }

    }
    .bottom-section-tab-content{        
        @include media-breakpoint-down(md) {
            padding: 0px 0 0px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 0px 0 0px 0 !important;
        }

    }

    .select-years-container{
        text-align: right;
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 30px;
        }
        label{
            color: #272727;
            font-family: Lato;
            font-size: 1.125rem;
            letter-spacing: 0.5px;
            display: inline-block;
            text-transform: uppercase;

            position: relative;
            top: 1px;
            margin-right: 15px;
            @include media-breakpoint-down(md) {
                top: 0px;
                margin-right: 0px;
                text-align: center;
            }


        }
        .custom-select{
            display: inline-block;
            width: 367px;
            @include media-breakpoint-down(md) {
                width: 100%;                
            }

            .select-items{
                max-height: 445px;
                overflow-y: auto;
                padding: 30px 40px;
                @include media-breakpoint-down(md) {
                    padding: 30px 20px;
                }
                div{
                    float: left;
                    width: 50%;
                    line-height: 35px;
                    text-align: center;
                    padding: 5px;
                }
            }
        }
    }

    .k-chart{
        margin-bottom: 0px;
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        }
    }

    
    
    
}

.k-chart-tooltip{

    

    border: none !important;
    background: transparent !important;
    box-shadow: none !important;

    .annualized-tooltip{
        position: relative;
        margin-left: -165px;
        margin-top: -140px;    

        @include media-breakpoint-down(lg) {
            margin-left: -135px;
            margin-top: -140px;
        }
        @include media-breakpoint-down(md) {
            margin-left: -135px; //-115
            margin-top: -140px;
        }
        @include media-breakpoint-down(sm) {
            margin-left: -120px;
            margin-top: -105px;            
        }
        @include media-breakpoint-down(xs) {
            margin-left: -105px;
            margin-top: -105px;
            display:none;
        }

        border: none !important;
        border-radius: 10px;
        background-color: #FFFFFF !important;
        box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        padding: 18px 15px !important;
        text-align: left;
        p, strong{
            font-size: 1rem !important;
            margin: 0;
            letter-spacing: 0.1px;
            @media screen and (max-width: 1350px) {    
                font-size: 0.875rem !important;
            }
            @include media-breakpoint-down(md) {
                font-size: 0.875rem !important;
            }
            @include media-breakpoint-down(sm) {
                font-size: 0.75rem !important;
                line-height: 18px;
            }
        }    

        &::after{
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #ffffff;
            border-width: 16px;
            margin-left: -16px;
        }
    }
    

    .year-to-date-tooltip{
        position: relative;
        margin-left: -135px;
        margin-top: -50px;

        @include media-breakpoint-down(lg) {
            margin-left: -135px;
        }
        @include media-breakpoint-down(md) {
            margin-left: -135px;
        }
        @include media-breakpoint-down(sm) {
            margin-left: -105px;         
        }
        @include media-breakpoint-down(xs) {
            margin-left: -105px;
        }

        border: none !important;
        border-radius: 10px;
        background-color: #FFFFFF !important;
        box-shadow: 0 0 20px 4px rgba(109,114,120,0.15);
        padding: 18px 15px !important;
        text-align: left;
        p, strong{
            font-size: 1rem !important;
            margin: 0;
            letter-spacing: 0.1px;
            @media screen and (max-width: 1350px) {    
                font-size: 0.875rem !important;
            }
            @include media-breakpoint-down(md) {
                font-size: 0.875rem !important;
            }
            @include media-breakpoint-down(sm) {
                font-size: 0.75rem !important;
                line-height: 18px;
            }
        }    

        // &::after{
        //     top: 100%;
        //     left: 50%;
        //     border: solid transparent;
        //     content: " ";
        //     height: 0;
        //     width: 0;
        //     position: absolute;
        //     pointer-events: none;
        //     border-color: rgba(255, 255, 255, 0);
        //     border-top-color: #ffffff;
        //     border-width: 16px;
        //     margin-left: -16px;
        // }
    }
}
