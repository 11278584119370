.investment-commitments {
    padding: 100px 0;

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    table {
        font-weight: 300;
        margin-bottom: 0px;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.5px;
        line-height: 25px;
        overflow: visible;

        @include media-breakpoint-down(lg) {
            overflow: hidden;
        }
        // tbody{
        //     overflow-y: auto;
        // }
        tbody {
            overflow: visible;

            @include media-breakpoint-down(lg) {
                overflow-x: auto;
                overflow-y: auto;
            }
        }

        tr.main-header {
            border-bottom: 3px solid $tcdrs-blue !important;

            td {
                font-size: 1.125rem;
                font-weight: 400;
                letter-spacing: 0.5px;
                line-height: 28px;
                text-transform: uppercase;
                border-top: none;
                border-bottom: none;
                vertical-align: bottom;

                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                    word-break: normal;
                    min-width: 220px;
                }

                &:first-child {
                    width: 180px;
                    min-width: 180px;
                    //padding-left: 34px;
                    @include media-breakpoint-down(sm) {
                        width: 150px;
                        min-width: 150px;
                    }
                }

                &:nth-child(2) {
                    width: 210px;
                    min-width: 210px;
                }

                &:nth-child(3) {
                    width: 440px;
                    min-width: 440px;
                }

                &:nth-child(4) {
                    width: 150px;
                    min-width: 150px;
                }

                &:nth-child(5) {
                    width: 200px;
                    min-width: 200px;

                    @include media-breakpoint-down(sm) {
                        width: 150px;
                        min-width: 150px;
                    }
                }
            }
        }

        tr {
            border-bottom: 1px solid $graphite;

            td {
                padding: 16px 12px;
                vertical-align: top;
                border-top: none;
                border-bottom: none;
                //border-top: 1px solid $graphite;
                span {
                    display: block;
                    text-align: center;
                }

                p {
                    font-size: 1rem;
                    font-weight: 300;
                    letter-spacing: 0.5px;
                    line-height: 25px;
                    margin-bottom: 0px;
                }
                //responsive shadow
                &:first-child {

                    @include media-breakpoint-down(md) {
                        // -webkit-box-shadow: none;
                        // -moz-box-shadow: none;
                        // box-shadow: none;
                        -webkit-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                        -moz-box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                        box-shadow: 6px 6px 12px -1px rgba(0,0,0,0.2);
                    }
                }

                &:nth-child(1) {
                    display: table-cell !important; //this should be block for ie11
                    z-index: 100;
                }
            }
        }
    }
}


*::-ms-backdrop, .investment-commitments table tr td:nth-child(1) {
    display: block !important; /* IE11 */
}

*::-ms-backdrop, .investment-commitments table tr.main-header td {
    vertical-align: top;
}
