.carousel-card {

    padding: 100px 0 88px 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0 60px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;

        .slick-list {
            padding: 2px 0;
        }
    }

    .col-md-8 p {
        margin-bottom: 3.5rem !important;

        &:last-child {
            margin-bottom: 12px !important;
        }
    }

    .title {
        margin-top: 12px;
        margin-bottom: 3rem !important;

        @include media-breakpoint-down(md) {
            margin: 0 !important;
        }
    }

    h1 {
        strong {
            color: $tcdrs-blue;
        }
    }

    .w-90 {
        width: 86.5%;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .cards {
        // margin: 0 auto;

        .slick-track {
            display: flex !important;
        }

        .slick-slide {
            height: auto;
            margin-bottom: 20px;
        }

        // .p-2{
        //     padding:.75rem!important;
        // }

        &.carousel {
            .card a.btn {
                margin-top: auto !important;
            }
        }
    }

    .w-50 {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    /*
    @include media-breakpoint-down(md) {
        >div[class*="col-"] {
            margin-bottom: -30px !important;
        }
    }
    */

    .card {
        // position: relative;
        // display: flex;
        // flex-direction: column;
        // min-width: 0;
        // word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: none;
        border-radius: 10px;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
        font-size: 1rem;
        line-height: 1.5625rem;
        padding: 3rem 2rem;
        height: 100%;

        img {
            // width: 73px;
            width: auto;
            margin: 0 auto 1.875rem auto;

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                width: 73px;
            }
        }


        h4 {
            font-size: 1.7rem;
            font-weight: 600;
            line-height: 1.85rem;
            margin-bottom: 0.625rem;

            &.eyebrow {
                font-size: 1rem;
                font-weight: 400;
                color: $tcdrs-blue;
            }
        }
        ul{
            li{
                text-align: left;
            }
        }
    }

    .p-12 {
        padding: 12px !important;

        @include media-breakpoint-down(sm) {
            padding: $mobile-gutter;
        }

        @include media-breakpoint-only(md) {
            padding: 20px !important;
        }

        @include media-breakpoint-only(lg) {
            padding: $mobile-gutter;
        }
    }

    .p-20 {
        padding: 20px !important;

        @include media-breakpoint-down(sm) {
            padding: $mobile-gutter;
        }

        @include media-breakpoint-only(md) {
            padding: 20px !important;
        }

        @include media-breakpoint-only(lg) {
            padding: $mobile-gutter;
        }
    }

    .with-arrow {
        margin: 40px 0 12px 0;
        text-decoration: none;
        color: white;
        @include media-breakpoint-down(sm) {
            margin: 20px 0 12px 0;
            letter-spacing: 3.5px;
        }
    }
    .cta-buttons{
        .btn{
            @include media-breakpoint-up(sm) {
                min-width: 282px;
            }
        }
    }
}

.bg-lBlue {
    background-color: $carousel-bg;
}

// .carousel-card .cards.carousel .card a.btn{
// 	margin-top: auto !important;
// }