.rich-text-component{

    &.white-promo-tout{ 
        padding: 100px 0;       
        .text-column{            
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .btn{
            margin-top: 30px;
            @include media-breakpoint-down(sm) {
                display: block;                
            }
        }

        .body-large{
            margin-bottom: 0px;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0px !important;
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 0px !important;
            .text-column{                
                text-align: center; 
            }
            
        }
    }

    //blue promo tout
    &.blue-promo-tout{
        background: $tcdrs-blue;
        color: white;
        padding: 100px 0; 
        @include media-breakpoint-down(md) {    
            padding: 60px 0;  
        } 
        // .text-column{
        //     padding: 66px 0;                    
        // }
                        
        h1, h2, h3, h4, h5, p{ color: white;}
        .btn.btn-secondary{
            border-color: transparent;
            @include media-breakpoint-only(lg) { padding: 19px 0px; }
            
        }
        p a{
            color: white;
        }
        @include media-breakpoint-down(sm) { 
            padding: 40px 0px;               
            .text-column{                
                text-align: center;                                    
            }
            .btn{
                display: block; 
                width: 100%;        
            }
        }

        .text-container{
            h3{
                a, span, p{
                    font-size: inherit;
                }
                strong.yellow-text {
                    word-break: break-word !important;
                    // @include media-breakpoint-down(lg) {
                    //     word-break: break-word !important;
                    // }
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1.875rem;
                    line-height: 2.25rem;
                }
            }
              
        }

        .buttons-container{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            padding:0;
            @include media-breakpoint-only(md) { margin-right: 20px; }
            @include media-breakpoint-down(sm) { 
            margin-bottom: 30px;
            }
            &:last-child{
                @include media-breakpoint-down(sm) { 
                margin-bottom: 0;
                }
            }
        }
    }
   

    &.green-promo-tout{ 
        background: $tcdrs-green;
        color: white; 
        padding: 100px 0; 

        h1, h2, h3, h4, h5, p{ color: white;}
           
        .text-column{            
            display: flex;
            align-items: center;
            justify-content: center;
            p a{
                color: white;
            }
        }

        
        
        .btn{
            @include media-breakpoint-down(sm) {
                display: block;                
            }
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0px !important;
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 0px !important;
            .text-column{                
                text-align: center; 
            }
            
        }
    }


    &.three-column-promo-tout{
        background: $tcdrs-blue;
        color: white;
        padding: 100px 0;

        .three-cols{
            padding: 0 20px;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0px !important;
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 0px !important;
        }

        .text-column{
            padding: 0px 0 20px;
            p a{
                color: white;
            }

            .text-container{
                h3{
                    a, span, p{
                        font-size: inherit;
                    }
                    strong.yellow-text {
                        word-break: break-word !important;                
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 1.875rem;
                        line-height: 2.25rem;
                    }
                }
                  
            }
        }        
        h1, h2, h3, h4, h5, p{ color: white;}

        .three-cols .col-md-4{
            &:first-child{ padding-left: 0;}
            &:last-child{ padding-right:0;}

            p.body-large{
                span{                               
                    font-size: 2.188rem;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 45px; 
                    @include media-breakpoint-down(xl) {
                        font-size: 2rem;
                    } 
                    @include media-breakpoint-down(lg) {
                        font-size: 1.375rem;
                    } 
                    @include media-breakpoint-down(md) {
                        font-size: 1.125rem;
                    } 
                    @include media-breakpoint-down(sm) {
                        font-size: 1.625rem;
                    } 
                }
                a{
                    color: #FFFFFF;
                    font-family: Lato;
                    font-size: 1.563rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 32px;
                    text-decoration: none;
                    @include media-breakpoint-down(xl) {
                        font-size: 1.5rem;
                    } 
                    @include media-breakpoint-down(lg) {
                        font-size: 1.25rem;
                    } 
                    @include media-breakpoint-down(md) {
                        font-size: 1rem;
                    } 
                    @include media-breakpoint-down(sm) {
                        font-size: 1.25rem;
                    } 
                }
                
            }

            &.text-right{
                p.body-large{
                    a{
                        color: $tcdrs-yellow;                
                        font-size: 2.188rem;
                        letter-spacing: 0;
                        line-height: 45px;
                        @include media-breakpoint-down(xl) {
                            font-size: 1.75rem;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 1.375rem;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 1.125rem;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 1.625rem;
                        }
                        @include media-breakpoint-down(xs) {
                            font-size: 1.5rem;
                        }
                    }
                }

                .yellow-text {               
                    word-break: break-all;                    
                }
            }

            @include media-breakpoint-down(sm) {
                text-align: center !important;            
                //&:first-child{ padding-left: 0;}
                //&:last-child{ padding-right:0;}
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 0px 20px;
            .text-column{
                padding: 0px 0px 20px;
                text-align: center;
            }
        }
    }    


    &.bulleted-list{
        padding: 100px 0;
        &.lblue{
            background: $tcdrs-lblue;
        }
        @include media-breakpoint-down(md) {
            padding: 60px 0px;
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 0px;
        }
    }

    &.two-column-card{
        //breakpoint for large screen
        padding: 100px 0;  
        
        @include media-breakpoint-down(md) {
            padding: 60px 0px;
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 0px;
            h1, h2, h3, h4, h5, p{
                display:block;
                width: 100%;
                text-align: center;
            }

            .col-md-6{
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .text-container{
            border-radius: 10px;
            background-color: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
            height: 100%;
            padding: 40px 55px;

            @include media-breakpoint-down(md) {
                padding: 40px 27px;
            }
            @include media-breakpoint-down(sm) {
                padding: 40px 27px;
            }

            h4, h2{
                width: 100%;
                text-align: center;
            }
          
        }

        .with-arrow{
            margin: 40px 0 12px 0;
            text-decoration: none;
        }
       
    }



    //min width for buttons large screens
    .btn{
        @include media-breakpoint-up(md) {
            min-width: 250px;               
        }
    }
    
}


.disclaimer{
    padding-top: 1rem !important;
    @include media-breakpoint-up(md) {
        padding-top: 3rem !important;               
    }
}


.steps{
    &.bg-lBlue {
        padding: 15px 0 !important;
    }
    
    .header {
       padding: 25px 0 7px;
    }
    
    .box-shadow {
        padding: 40px 28px 10px;
        margin: 0;
    }
    
    h2{
        font-size: 2.188rem;
    }
    
    .small{
        font-size: 1rem !important;
            font-weight: 300 !important;
    }
    
    h2.step-title {
        color: #05347A;
        font-weight: 400;
        margin: 0.8rem 0 10px;
    }
    
    
    h5 {
        font-size: 1.563rem;
        margin-bottom: 0.65rem;
        font-weight: 500;
    }
    
    .with-arrow {
        margin: 30px 0;
    }
    
    @media (max-width: 575.98px) { 
        .with-arrow.last-one {
            margin-top: 20px;
        }
    }
    
    p {
        margin-bottom: 1.88rem;
    }
    
    .col-md-6 {
        padding-bottom: 0!important;
    }
    
    a.video-with-play-icon::before {
        top: 55%;
        font-size: 2.188rem;
    }
    
    .library-post.side-by-side .media-container .img-fluid {
        -webkit-box-shadow: 8px 8px 0px -3px #05347a;
        -moz-box-shadow: 8px 8px 0px -3px #05347a;
        box-shadow: 8px 8px 0px -3px #05347a;
    }
    
    .side-by-side .media-container.video {
        padding: 10px 0px 7px !important;
    }
    
    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
        .header {
            padding: 85px 0 37px;
            font-size: 3.438rem;
        }
    
        p {
            padding-right: 0.8rem;
        }
    
        .box-shadow {
            padding: 60px 102px 30px;
            margin: 15px 0;
        }
    
        h2{
            font-size: 2.188rem;
            line-height: 45px;
        }
    
        .with-arrow.last-one {
            margin-top: 30px;
        }
    
        a.video-with-play-icon::before {
            top: 50%;
            font-size: 3.125rem;
        }
            
        .library-post.side-by-side .media-container .img-fluid {
            height: 365px;
        }
    
        .side-by-side .media-container.video {
            padding: 30px 25px 30px !important;
        }
    }
}




