header {
  nav {
    background: white;
    a {
      color: black;
    }
  }
}

body{
  padding-top: 84px;
  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}


// Gutter for Mobile View
@include media-breakpoint-down(xs) {
  .container {
    padding-right: $mobile-gutter;
    padding-left:  $mobile-gutter;
    [class*="col-"] {
      padding-right: $mobile-gutter;
      padding-left: $mobile-gutter;
    }
    .row{
      margin-right: -$mobile-gutter;
      margin-left: -$mobile-gutter;
    }
  }
}
// Gutter for Tablet View
@media (max-width: 1240px) and (min-width: 992px){
  .container {
    max-width: initial;
    padding-right: $mobile-gutter;
    padding-left:  $mobile-gutter;
    [class*="col-"] {
      padding-right: $mobile-gutter;
      padding-left: $mobile-gutter;
    }
    .row{
      margin-right: -$mobile-gutter;
      margin-left: -$mobile-gutter;
    }
  }
}

