.btn {
  border-radius: 0;
  padding: .75rem 1.25rem;
  letter-spacing: 1.471px;

  line-height: 1.25rem;
  padding: 19px 20px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;


  //transition: transform 0.3s ease-out;

  &.btn-primary {
    background: $main-red;
    border: 1px solid $main-red;
    color: white;

    @media(hover: hover) and (pointer: fine) {
    &:hover,
    &:active//,
    //&:focus
        {
        background: white;
        color: $main-red;
        border: 1px $main-red solid;

            @include media-breakpoint-down(md) {
                background: $main-red;
                border: 1px solid $main-red;
                color: white;
            }
        }
    }

    &.carousel-arrow {
      border-radius: 100%;
      border: 2px solid $tcdrs-blue;
      background: $tcdrs-blue;
      padding: 14px 14px;
      width: 50px;
      height: 50px;

      &:hover {
        color: $tcdrs-blue;
        border: 2px solid $tcdrs-blue;
        background: white;
      }

      span {
        position: relative;
        left: 2px;
      }
    }
  }

  &.btn-secondary {
    background: white;
    border: 1px $main-red solid;
    color: $main-red;

    @media(hover: hover) and (pointer: fine) { //hove appllied only to desktop, not in mobile
    &:hover,
    &:active {
        background: $main-red;
        color: white;
        }
    }

    &.white {
      color: white;
    }
  }

  &.no-border {
    border: none;
  }

  &.no-background {
    background: transparent !important;
    color: $main-red !important;
    padding: 0;
    margin: 0 !important;
  }

  &.btn-circle {
    background: white;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 15px;
    text-align: center;
    line-height: 1.42857;

    &:hover {
      opacity: 0.8;
    }

    i {
      line-height: 1rem;
    }

    &.disabled i {
      color: #dedede;
    }

  }

  &.btn-social {
    background: white;

    i {
      color: $light-blue;
      font-size: 1rem;
      line-height: 2rem;
      margin-left: 3px;
    }
  }

  &.icon {
    padding-left: 1rem !important;

    i {
      font-size: 1.5rem;
      margin-right: .5em;
    }
  }

  &.btn-arrow {
    border-radius: 0rem !important;
    font-size: .8rem;
    border-bottom-right-radius: 15px !important;
  }

  &.small {
    padding: .7rem 1.5rem;
  }

  &.medium {
    padding: .7rem 3rem;
  }

  &.large {
    padding: .7rem 4.5rem;
  }

  &.x-large {
    padding: .7rem 6rem;
  }

  i {
    color: white;
  }

  &.with-arrow {

    text-decoration: none;
    // width: inherit;

    &::after {
      content: "\e90d";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 2px;
      transition: transform 0.22s ease-out;
    }

    &:hover::after {
      transform: translateX(5px);
    }
  }

  &.btn-back {

    &::before {
      content: "\e90d";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: 2px;
      transition: transform 0.22s ease-out;
      transform: translateX(0px) rotate(180deg);
    }

    &:hover::before {
      transform: translateX(-5px) rotate(180deg);
    }
  }

  &.w-max-content {
    width: max-content;
  }

}

section .btn,
.featured-publications .btn {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
  }
}

//end btn

.dropdown.filter {
  .dropdown-toggle {
    height: 50px;
    background: white;
    color: #272727;
    border: 1px solid black;
    padding: 0 20px;
    line-height: 50px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 1.125rem;
  }

  .dropdown-toggle::after {
    content: "\e907";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    border: none;
    transform: rotate(90deg);
    color: blue;
    position: relative;
    top: 8px;
    left: 3px;
  }

  &.show {
    .dropdown-toggle::after {
      transform: rotate(-90deg);
    }
  }
}

.pagination {
  .page-item {
    &.disabled .page-link {
      border-color: transparent;
    }

    &.active .page-link {
      font-weight: 700;
      color: $tcdrs-blue;
      background: transparent;
    }

    .page-link {
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.35rem;
      letter-spacing: 0.5px;
      color: $paragraph;
      border-color: transparent;
      padding: 8px 8px;

      &.disabled {
        color: $paragraph;
        opacity: 0.6;

        .icon-arrow_back_ios-24px,
        .icon-arrow_forward_ios-24px {
          font-size: 1.375rem;
          color: black;

        }
      }

      .icon-arrow_back_ios-24px,
      .icon-arrow_forward_ios-24px {
        font-size: 1.375rem;
        color: $tcdrs-blue;

      }
    }
  }


}


.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #f1f1f1 !important;
  border-color: #c5c5c5 !important;
}