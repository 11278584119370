body {
  font-size: 16px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: $paragraph;
}

a {
  color: $tcdrs-blue;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5625rem;
  letter-spacing: 0.5px;

  &:hover {}

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
}

//style only for anchors inside p

p {
  a {
    text-decoration: underline;
    color: #05347A;
    font-family: Lato;
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 28px;
  }
}

.underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h1 {
  color: black;
  font-size: 4.063rem;
  font-weight: 300;
  line-height: 4.75rem;

  &.medium {
    font-size: 2.5rem;
    line-height: 2.625rem;
  }

  b,
  strong {
    color: $tcdrs-yellow;
    font-weight: 700;
  }

  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
    line-height: 2.625rem;
  }
}

h2 {
  color: black;
  font-size: 3.4375rem;
  font-weight: 300;
  line-height: 3.75rem;

  &.medium {
    font-size: 2.1875rem;
    line-height: 2.25rem;
  }

  b,
  strong {
    color: $tcdrs-blue;
    font-weight: 700;
  }

  @include media-breakpoint-down(md) {
    font-size: 2.1875rem;
    line-height: 2.25rem;
  }
}

h3 {
  color: black;
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.5rem;

  b,
  strong {
    color: $tcdrs-blue;
    font-weight: 700;
  }

  &.medium {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

h4 {
  color: $tcdrs-blue;
  font-size: 2.1875rem;
  font-weight: 400;
  line-height: 2.8125rem;

  &.medium {
    font-size: 1.5rem;
    letter-spacing: 1.25px;
    line-height: 1.5625rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    letter-spacing: 1.25px;
    line-height: 1.5625rem;
  }
}

h5 {
  color: $tcdrs-blue;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 2rem;

  &.medium {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 2rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.body-large,
p {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
  letter-spacing: 0.5px;

  &.medium {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.body-small,
ul li {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5625rem;
  letter-spacing: 0.5px;

  &.medium {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
}

.eyebrow {
  color: $paragraph;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5625rem;
  letter-spacing: 4px;
  text-transform: uppercase;

  &.medium {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
}

.breadcrumb {
  color: $paragraph;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5625rem;
  letter-spacing: 0.5px;

  &.active {
    color: $tcdrs-blue;
    font-weight: 400;
  }

  &.medium {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
}

.yellow-text {
  color: #FF9D52 !important;
}

.text-container {
  width: 100%;

  ul {
    padding-left: 30px;
  }

  ul,
  .body-small,
  .body-large {
    width: 100%;
  }

  ul>li {
    margin-bottom: 10px;
  }
}

//Blue bold text
.blue {
  color: $tcdrs-blue;
}

.blue-bold {
  color: $tcdrs-blue;
  font-weight: bold;
}

.medium {
  font-size: 1rem !important;
}

.small {
  font-size: .75rem !important;
}

.text-uppercase {
  text-transform: uppercase;
}