.table-responsive-title{
    h4{
        @include media-breakpoint-down(md) {
            margin-top: 30px !important;
            margin-bottom: 20px !important;
            padding: 0 30px !important;
        }
    }
}

.container [class*="col-"].table-responsive {
    @include media-breakpoint-down(sm) {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        padding-left: 0 !important;
        margin-left: $mobile-gutter;
        margin-right: $mobile-gutter;
    }

    table {
        thead {
            tr {
                th {
                    text-transform: uppercase;
                    font-size: 1.1rem;
                    font-weight: 500;
                    border-bottom: 3px $tcdrs-blue solid;

                    @include media-breakpoint-down(md) {
                        border-bottom: 3px $light-blue solid;
                    }

                }
            }
        }

        td,
        th {
            color: $paragraph;
            font-weight: 300;
            border-top: 0;
            border-bottom: 1px black solid;

            @include media-breakpoint-down(md) {
                // -webkit-box-shadow: inset 10px -3px 12px -7px rgba(95, 52, 52, 0.2);
                // -moz-box-shadow: inset 10px -3px 12px -7px rgba(0, 0, 0, 0.2);
                // box-shadow: inset 10px -3px 12px -7px rgba(0, 0, 0, 0.2);
                padding: 1rem 1rem 1rem 1rem;

                &:first-child {
                    padding: 1rem 3rem 1rem 0;
                    position: sticky;
                    position: -webkit-sticky;
                    left: 0;
                    background: white;
                    -webkit-box-shadow: 6px 2px 12px -1px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 6px 2px 12px -1px rgba(0, 0, 0, 0.2);
                    box-shadow: 6px 2px 12px -1px rgba(0, 0, 0, 0.2);
                    white-space: initial;
                }
            }
        }

        th {
            padding: 1rem;
        }
    }
}

.container{
    .bd-highlight{
        @include media-breakpoint-down(md) {
            margin-bottom: 40px !important;
        }
        .col-12.col-md-3:last-child{
            a.btn{
                @include media-breakpoint-down(md) {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

//50144
.col-12.col-md-12.text-center.mb-5 a.btn {
    min-width: 210px;
}
