.general-search-component{

    padding: 100px 0;

    .display-message{
        h5{
            font-weight: 400;
        }
    }

    #searchInput{
        &.change-border{
            border-radius: 28px 28px 0 0;
        }
    }

    .search-list-container{
        margin-bottom: 60px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }

        .search-list-item{

            border-bottom: 1px solid $graphite;
            padding: 30px 0;
            @include media-breakpoint-down(sm) {
                padding: 30px 28px;
                margin: 0 0px !important;
            } 

            .image-left{
                @include media-breakpoint-down(sm) {
                    display: none !important;
                } 
            }

            .text-column{
                h5{
                    font-weight: 400;
                }

                @include media-breakpoint-down(sm) {
                        padding: 0 !important;
                        margin: 0 -28px !important;                  
                } 
            }
            
            .buttons-container{
                text-align: center;
                .btn{
                    @include media-breakpoint-down(sm) {
                        display:flex !important;
                        width: auto;
                    } 
                }

                @include media-breakpoint-down(sm) {
                    padding: 0 !important;
                    margin: 0 -28px !important;                  
                }

            }
        }        
    }

    .search-list-pagination{
        .easyPaginateNav{

            @include media-breakpoint-down(sm) {
                width: 100%;
                flex-direction: row;
                display: flex;
                flex-wrap: nowrap;
            }

            a{
                text-decoration: none;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.35rem;
                letter-spacing: 0.5px;
                color: #272727;
                border-color: transparent;
                padding: 8px 8px;

                &.current{
                    font-weight: 700;
                    color: $tcdrs-blue;
                    background: transparent;
                    text-decoration: underline;
                }
                &:hover{
                    text-decoration: none;
                }

                @include media-breakpoint-down(sm) {
                    width: 50%;
                    text-align: center;
                }
            }
            a.prev, a.next{
                font-size: 1.375rem;
                color: $tcdrs-blue;
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: relative;
                top: 3px;
                @include media-breakpoint-down(sm) {
                    top: 0px;
                }

                &.disabled{
                    color: $tcdrs-lgrey;
                    background: transparent !important;
                }
            }
            a.prev{
                &::before{
                    content: "\e906";
                }
            }
            a.next{
                &::before{
                    content: "\e907";
                }
            }
        }
    }

}