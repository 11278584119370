.board-meetings {

    &.component-p-100{
        padding: 100px 0;
        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }
    }

    .table-responsive {
        table {
            margin: 0;

            tr {

                th,
                td {
                    text-align: left;
                    padding: 1rem .75rem;

                    &:first-child {
                        min-width: 210px;
                    }

                    a{
                        text-decoration: underline;
                    }
                }

                th {
                    padding: .5rem .75rem;
                }
            }

        }
    }

    .selected-data {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        h3,
        h2 {
            line-height: 3.45rem;
            font-size: 2.85rem;
        }

        h4 {
            margin-bottom: 0!important;
        }
        .line-height-2{
            margin-bottom: 10px!important;
            font-size: 1rem;
        } ;
    }
}