.testimonials-component{

    background: $tcdrs-blue;
    padding: 100px 0 100px;

    @include media-breakpoint-down(md) {    
        padding: 40px 0 65px;
    }

    > .container{
        @include media-breakpoint-between(sm, lg) {
            padding: 0 !important;
            max-width: 100%;
            }
    }

    .carousel-testimonials.slick-slider{
        margin-bottom: 60px;
        @include media-breakpoint-down(lg) {  
            margin-bottom: 40px;
        }
         
        @include media-breakpoint-down(sm) {  
            margin-bottom: 30px;
        }


        .slick-dots{
            bottom: -80px;
            @include media-breakpoint-down(lg) {  
                bottom: -60px;
            }
            @include media-breakpoint-down(sm) {  
                bottom: -55px;
            }
        }


    }
    .title-section{
        color: white;
        h1,h2,h3,h4,h5,p{color: white;}
        h2 strong{color: $tcdrs-yellow;}
        @include media-breakpoint-only(lg) {                
            padding: 0 56px !important;
            margin: 0 24px 24px !important;
        }
        @include media-breakpoint-down(md) {    
            padding: 0 28px !important;
            margin: 0 24px 24px !important;
        }
        @include media-breakpoint-down(sm) {    
            padding: 0 28px !important;
            margin: 0 12px 24px !important;
        }
        @include media-breakpoint-down(xs) {    
            padding: 0 16px !important;
            margin: 0 12px 24px !important;
        }
        

    }

    .slick-list {
        padding-top: 50px;
        min-height: 500px;
        @include media-breakpoint-down(lg) {    
            padding-top: 0px;
            min-height: auto;
        }
    }
    .slick-dots {
        bottom: -45px;
        left: 0;
        li {
            button {
              border: 1px solid white;
              border-radius: 100%;
            }
          
            &.slick-active button {
              border: 2px solid white;
              border-radius: 100%;
              background: white;
            }
        }
    }

    .slick-prev {
        left: -70px;
        width: 50px;
        height: 50px;
        &:before {
            content: "\e904";
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            margin-left: 0;
            position: relative;
            top: 0;
            transition: transform 0.22s ease-out;
            color: $tcdrs-blue;
            background: white;
            border-radius: 100%;
            padding: 15px;
            opacity: 1;
        }
        @include media-breakpoint-down(sm) {    
            display: none !important;
        }
    }
    .slick-next {
        right: -70px;
        width: 50px;
        height: 50px;
        &:before {
            content: "\e905";
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            margin-left: 0;
            position: relative;
            top: 0;
            transition: transform 0.22s ease-out;
            color: $tcdrs-blue;
            background: white;
            border-radius: 100%;
            padding: 15px;
            opacity: 1;
        }
        @include media-breakpoint-down(sm) {    
            display: none !important;
        }
    }
    

    .slide-testimonials{

        .quotation-icon{
            margin-bottom: 20px;
        }
       
        .media-container{
            padding: 0; background: $tcdrs-blue;
            @include media-breakpoint-between(xs, lg) {
                display: none;
            }
        }

        .img-fluid{

            position: absolute;
            width: 100%;
            max-width: 384px;
            display: block !important;
            left: -67px; //-8.33333%;
            top: -50px;
            z-index: 9999; 
            
            @include media-breakpoint-between(sm, lg) {
                position: relative;
                width: 50%;
                max-width: 384px;
                display: block !important;
                left: 13px; 
                top: 0px;
                z-index: 9999;
            } 
            @include media-breakpoint-only(lg) {  
                left: 28px; 
            }
            @include media-breakpoint-down(xs) {                
                position: relative;
                left: 0;
                position: relative;
                left: 0;
                top: 0;
                max-width: 100%;
            }  
        }

        .side-by-side .text-column {
            padding: 0 !important;

            @include media-breakpoint-down(xs) {                
                    flex-direction: column;
                    padding: 0 !important;
            }

            .text-container{
                background: white;
                padding: 60px 60px 60px 370px;
                min-height: 450px;
                @include media-breakpoint-down(lg) {
                    min-height: auto;
                    text-align: left;
                    // padding: 40px 35px 80px 35px;        
                    //padding: 40px 35px 40px 35px;        
                    padding: 40px 60px 40px 58px;
                }
                @include media-breakpoint-down(md) {
                    padding: 40px 30px 40px 43px; 
                }
                @include media-breakpoint-down(sm) {
                    min-height: auto;
                    text-align: left;           
                    padding: 40px 30px 40px 43px;
                }

                h5{
                    color: $paragraph;
                    font-weight: 400;
                }
                hr{
                    border-width: 2px;
                    border-color: $tcdrs-blue;
                    width: 50px;                    
                    margin: 25px auto 25px 0;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                .body-large{
                    color: $tcdrs-blue;
                    font-weight: 700;
                }
            }
        }
        
        
    }
    
}


.slide-testimonials .side-by-side > .container{
    @include media-breakpoint-between(sm, lg) {
    padding: 0 !important;
    max-width: 100%;
    }
}