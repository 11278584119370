.hero {
  overflow: hidden;
  background-color: $light-blue !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  &:not(.no-image) {
    // background-image: linear-gradient(rgba(0, 0, 0, .9), rgba(0, 0, 0, 0.9));
    // background-blend-mode: overlay;
    box-shadow: inset 0 0 150px 250px rgba(0, 0, 0, 0.5);

    @include media-breakpoint-down(xs) {
      box-shadow: none;
    }
  }

  .row {
    height: 500px;

    @include media-breakpoint-down(xs) {
      height: auto;
    }
  }

  @include media-breakpoint-down(xs) {
    // min-height: 550px;
    background-size: contain !important;
  }

  .hero-text {
    @include media-breakpoint-down(xs) {
      margin-top: 250px;
      padding: 2.75rem 2rem;
      background-color: $light-blue;
    }
  }

  h1,
  h5 {
    color: white;
    text-align: center;
    overflow-wrap: break-word;
  }

  h1,
  h1 strong {
    font-size: 4.063rem;

    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
    }
  }

  h5,
  h5 strong {
    font-size: 1.563rem;

    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }

  h5 strong {
    font-weight: 500;

    @include media-breakpoint-down(md) {
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0.5px;
    }
  }

  &.no-image {
    .row {
      height: 300px;

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }

    h5 strong {
      font-weight: 300;
    }

    @include media-breakpoint-down(md) {
      min-height: auto;

      .hero-text {
        margin-top: 0;
      }
    }
  }
}

// ---------------- Alert Wrapper (wrapps both browser and general alert container -------------------------
.alert-wrapper {
  position: fixed;
  top: 115px;
  right: 28px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 999999; // Needs to be ontop of the Feedback widget which has a z-index of 999995
  min-width: 384px;
  max-width: 384px;

  @include media-breakpoint-down(sm) {
    top: 75px;
    min-width: auto;
    left: $mobile-gutter;
    right: $mobile-gutter;
  }

  @include media-breakpoint-only(md) {
    top: 75px;
    width: 384px;
    left: auto !important;
    right: 28px !important;
  }

  @include media-breakpoint-only(sm) {
    top: 75px;
    width: 384px;
    left: auto !important;
    right: 28px !important;
  }

  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 825px) {
    left: auto !important;
    right: 28px !important;
    top: 65px;
  }
}

// ---------------- Alert Container -------------------------
.alert-container {
  position: relative;
  display: none;
  width: 100%;
  padding: 0;
  max-height: 450px;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    max-height: 300px;
  }
  // Landscape mobile devices
  // Prevents from being cut off and not shown completely
  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 825px) {
    height: calc(100vh - 110px);
  }
}

.alert-container.show {
  display: flex;
  flex-direction: column;
}

// ----------------  Alert Block Styles ----------------
.alert-block {
  position: relative;
  background: white;
  padding: 25px 40px;
  display: none;
  width: 100%;

  strong {
    font-weight: 700;
    color: $tcdrs-blue;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
}

//close buttons
.alert-block > .close,
.alert-container > .close {
  position: absolute;
  top: 10px;
  right: 17px;
  color: $tcdrs-blue;
  font-size: 2rem;
  opacity: 1;
  z-index: 2;
}

.general-alert {
  padding-bottom: 0 !important;
}

.alert-container .general-alert:not(:first-child) {
  padding-top: 0;
}

.alert-container .general-alert:not(:last-child) {
  .alert-separator {
    width: 100%;
    height: 2px;
    background: $tcdrs-blue;
  }
}

// ---------------- AlertBlock and Browser Alert Shared responsive Styles -------------------------
@include media-breakpoint-down(sm) {
  .browser-alert,
  .alert-block {
    padding: 25px;

    strong,
    p {
      font-size: 1rem !important;
    }
  }
}
