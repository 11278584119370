@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?mbheol');
  src:  url('../fonts/icomoon.eot?mbheol#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?mbheol') format('truetype'),
    url('../fonts/icomoon.woff?mbheol') format('woff'),
    url('../fonts/icomoon.svg?mbheol#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Favicon:before {
  content: "\e90f";
  color: #203371;
}
.icon-PlayButton-1 .path1:before {
  content: "\e911";
  color: rgb(255, 255, 255);
}
.icon-PlayButton-1 .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-MembersIcon:before {
  content: "\e935";
}
.icon-EmployersIcon:before {
  content: "\e90e";
}
.icon-ArrowCopy:before {
  content: "\e90d";
  color: #000;
}
.icon-facebook:before {
  content: "\e910";
}
.icon-instagram:before {
  content: "\e912";
}
.icon-pinterest:before {
  content: "\e914";
}
.icon-twitter:before {
  content: "\e919";
}
.icon-youtube:before {
  content: "\e91a";
}
.icon-arrow_back_ios-24px:before {
  content: "\e906";
}
.icon-arrow_forward_ios-24px:before {
  content: "\e907";
}
.icon-cancel-24px:before {
  content: "\e908";
}
.icon-chevron_left-24px:before {
  content: "\e909";
}
.icon-chevron_right-24px:before {
  content: "\e90a";
}
.icon-close-24px:before {
  content: "\e90b";
}
.icon-done-24px:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e902";
}
.icon-eye-off:before {
  content: "\e903";
}
.icon-arrow-thin-left:before {
  content: "\e904";
}
.icon-arrow-thin-right:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e900";
}
.icon-information-solid:before {
  content: "\e901";
}
