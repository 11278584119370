.calculation{
    ul{
        li{
            margin-bottom: .6rem;
        }
    }
    small{
        font-weight: 300;
        line-height: .5rem;
    }

    @include media-breakpoint-down(sm) {
        h2,h3,h4,h5,p{
            text-align: center;
        }
    }

    &.bg-lBlue{
        padding-top: 100px;

        @include media-breakpoint-down(md) {
            // padding: 40px 0 0 0 !important;
            padding: 60px 0 !important;
        }

        @include media-breakpoint-down(sm) {
            // padding: 40px 0 0 0 !important;
            padding: 40px 0 !important;
        }

        .blue-bold{
            margin-bottom: 1rem !important;
        }

        .col-md-8 p.medium{
            margin-bottom: 2rem !important;
            &:last-child {
                margin-bottom: 22px !important;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0px !important;
                }
            }
        }

        .col-md-6.mb-3.order-md-1{
            @include media-breakpoint-down(xs) {
                margin-bottom: 0px !important;
                padding-bottom: 0px !important;
            }
        }
    }


    .content-section{
        >.row{
            .col-md-6{
                &:first-child{
                    padding-bottom: 0 !important;
                    ul{
                        margin-bottom: 0;
                    }
                }
                &:nth-child(2){
                    ul{
                        margin-top: 0 !important;
                    }
                    
                }
            }
        }
    }


    //.container .col-md-6.pl-4 .text-container {
    //    height: 100% !important;
    //}


}

.calculation .row > .col-md-12 .row .col-md-6{
    @include media-breakpoint-down(sm) {
        padding-bottom: 40px;
    }
    @include media-breakpoint-down(xs) { //50132
        padding-bottom: 0px;
    }
}

.col-md-12.text-center{
    .calculation.bg-lBlue {
        padding-top: 60px;
        @include media-breakpoint-down(md) {
            padding: 40px 0 0 !important;
        }
    }   
}