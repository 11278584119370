footer {
    background-color: $light-blue;
    padding: 50px 0 0;

    a,
    span,
    p {
        font-size: .875rem;
        color: white;
        font-weight: 300;
        line-height: 1.25rem;
        letter-spacing: 0;
        // line-height: 20px;

        &:hover {
            color: white;
        }
    }

    span {
        @include media-breakpoint-down(md) {
            //display: flex;
            justify-content: center;
            margin-bottom: .5rem;
        }
    }

    @include media-breakpoint-down(md) {
        .col-6 {
            margin-bottom: 2rem;
        }
    }

    h3 {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        // font-size: 1rem;
        margin-bottom: .5rem;
        font-size: .875rem;
        line-height: 20px;

        @include media-breakpoint-down(md) {
            &.d-inline {
                display: block !important;
                margin-bottom: 1rem;
            }

            text-align: center;
            margin: 1rem 0;
        }
    }

    ul {
        padding: 0;

        li {
            margin-bottom: 0.5rem;
            list-style: none;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            a {
                color: $footer-text;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .text-center {
            text-align: left !important;
        }
    }

    @include media-breakpoint-down(md) {
        .text-center {
            margin-bottom: 2rem;
        }
    }

    .logo {
        @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
        }

        img {
            width: 75px;

            @include media-breakpoint-down(md) {
                width: 125px;
            }

            @include media-breakpoint-up(lg) {
                width: 75px;
                height: 40px;
            }
        }
    }

    .footer-copy {
        background: $dark-blue;
        color: $footer-text;
        padding: 15px 0 0 0;

        @include media-breakpoint-only(md) {
            .col-12:first-child {
                span {
                    float: left;
                    width: 44%;

                    &:last-child {
                        float: right;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            padding: 44px 0 44px 0;

            .d-flex {
                display: block !important;
            }

            [class*="col-sm"] {
                text-align: center;
            }

        }

        span {
            // margin-left: 10px;
            // font-weight: 100;

            @include media-breakpoint-down(md) {
                text-align: center;
                line-height: 2rem;

                &:last-child {
                    margin-left: 0 !important;
                }
            }
        }

        .social-icons {
            span {
                width: 30px;
                height: 30px;
                font-size: 1.875rem;
                &:hover {
                    opacity: 0.8;
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                }

                margin-left: 1.6rem;

                @include media-breakpoint-only(md) {
                    margin-left: .25rem;
                }
                @include media-breakpoint-only(lg) {
                    margin-left: .25rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            h3 {
                letter-spacing: .5px;
                font-size: 1.125rem;
                line-height: 1.75rem;
                margin-top: 0 !important;
            }

            span,
            a {
                font-size: 1rem;
                display: inline-flex;
                line-height: 1.563rem;
            }
        }

        @include media-breakpoint-down(sm) {
            h3 {
                margin-top: 15px !important;
            }

            span {
                width: 80%
            }
        }

        @include media-breakpoint-only(md) {
            .social-icons {
                .btn-social {
                    margin-left: .4rem !important;
                }
            }
        }

        @include media-breakpoint-only(sm) {
            .social-icons {
                a {
                    margin: 0 !important;
                }
            }
        }
    }
}