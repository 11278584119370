$container-width: 1200px!important;
@include media-breakpoint-up(lg) {
    .container {
      max-width: $container-width;
    }
}

$fa-font-path: "../webfonts";

$main-blue: #0d223f;
$main-red:#7F001A;
$main-text:#2d2d2d;
$btn-hover:#7F001A;
$disabled:#dedede;
$light-blue:#2A374D;
$dark-blue:rgba(0,0,0,0.3);
$footer-text:#ffffff;
$bg-lblue: #E9F6FF;
$mobile-menu-expanded:#E9F6FF;
$mobile-menu-active:#05347A;

$paragraph: #272727;

//styleguide colors:
$tcdrs-blue: #05347A;
$tcdrs-red: #7F001A;
$tcdrs-dgrey: #2A374D;
$tcdrs-lgrey: #7F8184;
$tcdrs-lblue: #E9F6FF;
$tcdrs-yellow: #FF9D52;
$tcdrs-green: #204E4C;
$tcdrs-au :#B15E1A;
$tcdrs-b :#C54F28;
$tcdrs-c :#D53A42;

$error: #E90A02;
$confirmation: #1AAA55;
$focus: #05347A;
$warning: #FFCC01;
$carousel-bg:#E9F6FF;
$graphite: #6d7278;
$mobile-gutter: 28px!important;
$gray-box:#F0F0F0;

