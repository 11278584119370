
//$grid-gutter-width: 24px;
// Grid columns



// $grid-breakpoints: (
//     xs: 0,
//     sm: 640px,
//     md: 1024px,
//     lg: 1200px,
//     xl: 1440px
// );
// Custom map of gutter widths across breakpoints.
$grid-gutter-widths: (
  xs: 16px,
  md: 24px,
);
// Default Bootstrap gutter width variable.
$grid-gutter-width: map-get($grid-gutter-widths, md);

  
